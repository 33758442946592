/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    type: 'group',
    children: [
        {
            id: 'tabler-icons1',
            title: 'Dashboard',
            type: 'item',
            url: 'super-distributor-employee/dashboard',
            breadcrumbs: true
        },
        {
            id: 'sdEmployee',
            title: 'List Batch',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons2',
                    title: 'Pending',
                    type: 'item',
                    url: 'super-distributor-employee/list-batches',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons3',
                    title: 'Success',
                    type: 'item',
                    url: 'super-distributor-employee/list-batches-success',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons4',
                    title: 'Failed',
                    type: 'item',
                    url: 'super-distributor-employee/list-batches-failed',
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'tabler-icons5',
            title: 'Employee Transactions',
            type: 'item',
            url: 'super-distributor-employee/list-employee-transactions',
            breadcrumbs: true
        }
    ]
};

export default Dashboard;
