/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1123334',
            title: 'Dashboard',
            type: 'item',
            url: 'zoneHeadDashboard/dashboard',
            breadcrumbs: true
        },
        {
            id: 'icons2231222',
            title: 'Super Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31210107009',
                    title: 'Add',
                    type: 'item',
                    url: 'zoneHeadDashboard/addsuperdistributor',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322110300675',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listsuperdistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322110909115',
                    title: 'Mapping',
                    type: 'item',
                    url: '/zoneHeadDashboard/superdistributormapping',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231227',
            title: 'Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53221189001',
                    title: 'Add',
                    type: 'item',
                    url: '/zoneHeadDashboard/adddistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53221181129',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listdistributor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22312250001',
            title: 'State Heads',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312121017',
                    title: 'Add',
                    type: 'item',
                    url: 'zoneHeadDashboard/addstateheads',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532212118',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/liststateheads',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223121224',
            title: 'Middle Manager ',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31121015',
                    title: 'Add',
                    type: 'item',
                    url: 'zoneHeadDashboard/addmiddlemanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53212116',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listmiddlemanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223133223',
            title: 'Manager',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312331013',
                    title: 'Add',
                    type: 'item',
                    url: 'zoneHeadDashboard/addmanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532211334',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listmanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223123332',
            title: 'Csp/Sanchalak',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312101220',
                    title: 'Add',
                    type: 'item',
                    url: 'zoneHeadDashboard/addcsp',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532211222',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listcsp',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223123225364',
            title: 'Merchant',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons532211220309',
                    title: 'List',
                    type: 'item',
                    url: '/zoneHeadDashboard/listmerchant',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'tabler-icons0301313255',
            title: 'Daily Sales Monitoring',
            type: 'item',
            url: 'zoneHeadDashboard/dailySalesMonitoring',
            breadcrumbs: true
        },
        {
            id: 'icons223122133222',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31122103112',
                    title: 'POS',
                    type: 'item',
                    url: 'zoneHeadDashboard/posTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5323122111',
                    title: 'QR',
                    type: 'item',
                    url: '/zoneHeadDashboard/qrTxnTrack',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231223215367',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312112301009',
                    title: 'DMT',
                    type: 'item',
                    url: 'zoneHeadDashboard/dmtTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons513222113008',
                    title: 'BBPS',
                    type: 'item',
                    url: '/zoneHeadDashboard/bbpsTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532221131001',
                    title: 'RECHARGE',
                    type: 'item',
                    url: '/zoneHeadDashboard/rechargeTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532223111002',
                    title: 'MATM',
                    type: 'item',
                    url: '/zoneHeadDashboard/matmTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons533212211003',
                    title: 'AEPS',
                    type: 'item',
                    url: '/zoneHeadDashboard/aepsTxnTrack',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Dashboard;
