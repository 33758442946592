import { Box, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEffect } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { allTransactionSdAdminApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { LoadingButton } from '@mui/lab';
import { handleExcelDownloadGlobal } from 'utils/exports/excelDownload';
const breadCrumbRoutes = [{ label: 'Transaction History', path: '' }];
const TransactionHistory = () => {
    const today = dayjs().format('YYYY/MM/DD');
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [rowCount, setRowCount] = useState(0);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [searchString, setSearchString] = useState('');

    //handler functions>>>
    const handleSearchClick = () => {
        const pagination = true;
        getTransactionHistory(pagination);
    };
    const handleSearchChange = (event) => {
        setSearchString(event.target.value);
    };
    const handlePageSizeChange = (params) => {
        setPage(params.page + 1);
        setPageSize(params?.pageSize);
    };
    const handleFromDateChange = (newValue) => {
        setFromDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };
    const handleExcelClick = () => {
        const pagination = false;
        getTransactionHistory(pagination);
    };
    //api calls>>
    const getTransactionHistory = async (pagination) => {
        let payload = {};
        if (pagination) {
            setLoading(true);
            payload = { start: page, length: pageSize, fromdate: fromDate, todate: toDate, branchcode: searchString };
        } else {
            setLoadingExcel(true);
            payload = { start: '', length: '', fromdate: fromDate, todate: toDate, branchcode: searchString };
        }
        const response = await allTransactionSdAdminApi(payload);
        if (response?.status === 200) {
            const data = response?.data?.data;
            if (pagination) {
                setRowCount(response?.data?.totalRecordsWithFilter);
                setTransactions(data);
            } else {
                handleExcelDownloadGlobal(columns, data, 'TRANSACTION_HISTORY');
            }
        }
        setLoading(false);
        setLoadingExcel(false);
    };

    useEffect(() => {
        const pagination = true;
        getTransactionHistory(pagination);
        // eslint-disable-next-line
    }, [page, pageSize, fromDate, toDate]);
    const columns = [
        {
            field: 'sl',
            headerName: 'Sl',
            width: 30,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'Date',
            headerName: 'Date',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                const date = new Date(params.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (1 + date.getMonth()).toString().padStart(2, '0');
                const year = date.getFullYear();
                let hour = date.getHours();
                const minute = date.getMinutes().toString().padStart(2, '0');
                const meridiem = hour >= 12 ? 'PM' : 'AM';
                hour = hour % 12 || 12; // Convert hour to 12-hour format
                return `${day}-${month}-${year} ${hour}:${minute} ${meridiem}`;
            }
        },
        {
            field: 'agentid',
            headerName: 'Agent ID',
            width: 140,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.row.agentid === '') {
                    if (params.row.Distributor === '') {
                        return <span style={{ color: 'red' }}>{params.row.Super_Distributor}</span>;
                    } else {
                        return <span style={{ color: 'green' }}>{params.row.Distributor}</span>;
                    }
                } else {
                    return (
                        <span>
                            {/* {params.row.Agent} <br /> */}
                            <span style={{ color: 'blue' }}>{params.row.agentid}</span>
                        </span>
                    );
                }
                //   return text;
            }
        },
        {
            field: 'combinedField',
            headerName: 'Transferred By',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                const { Agent, Distributor, Super_Distributor } = params.row;
                let color = '';
                if (Agent) {
                    color = 'blue'; // Set color to blue if field1 is present
                } else if (Distributor) {
                    color = 'green'; // Set color to green if field2 is present
                } else if (Super_Distributor) {
                    color = 'red';
                }
                if (Agent) {
                    return (
                        <span>
                            {Agent}
                            <br />
                            {/* {SdClassInfo != '1' ? <span style={{ color: color }}>(Agent)</span> : null} */}
                        </span>
                    );
                } else if (Distributor) {
                    return (
                        <span>
                            {Distributor} <br />
                            <span style={{ color: color }}>(Distributor)</span>
                        </span>
                    );
                } else if (Super_Distributor) {
                    return (
                        <span>
                            {Super_Distributor} <br />
                            <span style={{ color: color }}>(Super Distributor)</span>
                        </span>
                    );
                }

                return null;
            }
        },
        {
            field: 'Description',
            headerName: 'Description',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'Debit',
            headerName: 'Debit',
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'red' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'Credit',
            headerName: 'Credit',
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'green' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'interchange',
            headerName: 'Inter Change charge',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'Balance',
            headerName: 'Branch Balance',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'mfl_branch',
            headerName: 'MFL Branch FDGL',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },

        {
            field: 'txn_id',
            headerName: 'Transaction ID',
            width: 200,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'txnAmount',
            headerName: 'Transaction Amount',
            width: 200,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'commission',
            headerName: 'Commission Amount',
            width: 200,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'mfl_opening_balance',
            headerName: 'MFL Opening Balance',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'mfl_balance',
            headerName: 'MFL Total  Balance',
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            width: 200,
            renderCell: (params) => {
                if (params.value) {
                    return <span style={{ color: 'blue' }}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        }
    ];

    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: 2,
                        marginBottom: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <DatePicker
                            label="From Date"
                            value={fromDate ? dayjs(fromDate, 'YYYY/MM/DD') : null}
                            onChange={handleFromDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate ? dayjs(toDate, 'YYYY/MM/DD') : null}
                            onChange={handleToDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <TextField onChange={handleSearchChange} variant="outlined" placeholder="Search by MFL Branch" />
                        <LoadingButton loading={loading} onClick={handleSearchClick}>
                            Search
                        </LoadingButton>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="success"
                            size="small"
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExcelClick}
                            loading={loadingExcel}
                        >
                            Export to Excel
                        </LoadingButton>
                    </Box>
                    {/* {loading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />} */}
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={transactions}
                            columns={columns}
                            pagination
                            getRowId={(row) => row.txn_id || row.key}
                            paginationMode="server"
                            rowCount={rowCount}
                            rowsPerPageOptions={[25, 50, 100]}
                            Transaction
                            History
                            pageSize={pageSize}
                            // onPageChange={handlePageChange}
                            onPaginationModelChange={(params) => handlePageSizeChange(params)}
                            loading={loading}
                            // slots={{ toolbar: GridToolbar }}
                        />
                    </div>
                </Box>
            </LocalizationProvider>
        </>
    );
};

export default TransactionHistory;
