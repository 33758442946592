import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteSanchalak from 'routes/protectedRouteSanchalak';

const DashboardSanchalak = Loadable(lazy(() => import('views/sanchalakDashboard/dashboard')));
const RelationManagerProfile = Loadable(lazy(() => import('views/sanchalakDashboard/profile')));
const SuperDistributorForm = Loadable(lazy(() => import('views/sanchalakDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/sanchalakDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/sanchalakDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/sanchalakDashboard/distributor/add')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/sanchalakDashboard/viewsuperdistributor')));
const ViewDistributor = Loadable(lazy(() => import('views/sanchalakDashboard/viewdistributor')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/sanchalakDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/sanchalakDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/sanchalakDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/sanchalakDashboard/viewMerchant')));

export const sanchalakRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteSanchalak element={<DashboardSanchalak />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteSanchalak element={<RelationManagerProfile />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteSanchalak element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteSanchalak element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteSanchalak element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteSanchalak element={<DistributorForm />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteSanchalak element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteSanchalak element={<ViewDistributor />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteSanchalak element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteSanchalak element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteSanchalak element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteSanchalak element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteSanchalak element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteSanchalak element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteSanchalak element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteSanchalak element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteSanchalak element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteSanchalak element={<ViewMerchant />} />
        }
    ]
};
