import Dialog from '@mui/material/Dialog';
import { useEffect } from 'react';
import { useState } from 'react';
import fingerPrintGif from '../../../../../assets/gif/Finger_print_scanning.gif';
// eslint-disable-next-line
function ViewCustomerAuth({ showModal }) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(true);
    }, [showModal]);
    return (
        <>
            <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <img src={fingerPrintGif} alt="Finger" width="100%" height="100%" />
            </Dialog>
        </>
    );
}

export default ViewCustomerAuth;
