import { useState } from 'react';
import { TextField, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
const DateRangePickerComponent = ({ onDateChange }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const handleFromDateChange = (date) => {
        setFromDate(date);
        if (onDateChange) {
            onDateChange({ fromDate: date, toDate });
        }
    };

    const handleToDateChange = (date) => {
        setToDate(date);
        if (onDateChange) {
            onDateChange({ fromDate, toDate: date });
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" gap={2}>
                <DatePicker
                    label="From Date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label="To Date"
                    value={toDate}
                    onChange={handleToDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePickerComponent;

DateRangePickerComponent.propTypes = {
    onDateChange: PropTypes.func
};
