import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Link from '@mui/material/Link';
import { handleCatchError } from 'utils/CatchHandler';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import GlobalBackButton from 'views/utilities/BackButtonGlobal';
import { Link as RouterLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import BcConvertModal from 'views/Components/BcConvertModal';
import Spinner from 'views/Components/LoadingSinner';
import { checkMerchantOrBCAPI } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { customerEkycApi, ekycOtpVerifyApi, getCustomerOtpApi } from 'utils/API/Merchant APIs/DmtApiCalls';
import { handleCapture } from 'views/utilities/BiometricCapture';
import ViewCustomerAuth from './DmtAuthComponents/BioAuthAnimation';
import { Alert } from 'antd';
const commonStyles = {
    m: 0
};
export default function CreateCustomer() {
    const shouldshowEsign = localStorage.getItem('shouldshowEsign');
    const dmt_type = 1;
    const navigate = useNavigate();
    const [CustomerName, setCustomerName] = React.useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhoneno] = React.useState('');
    const [errName, setErrName] = useState('');
    const [merchantModalOpen, setMerchantModalOpen] = useState(false);
    const [isBcConvertPending, setIsBcConvertPending] = useState(false);
    const [isCheckLoading, setIsCheckLoading] = useState(true);
    const [showResponse, setShowResponse] = React.useState('');
    const location = useLocation();
    const mobilefromSearch = location?.state?.search;
    const info = location?.state?.info;
    const [aadharno, setAadharno] = useState('');
    const [errorAadhar, setErrorAadhar] = useState('');
    const [OTPPin, setOTPPin] = useState('');
    const [captureData, setCaptureData] = useState('');
    const [OtpId, setOtpId] = useState('');
    const [kycId, setKycId] = useState('');
    const [showFingerGif, setShowFingerGif] = useState(false);
    const agentid = localStorage.getItem('id');
    const handleBcModalClose = () => {
        setMerchantModalOpen(false);
    };
    //API CALL FOR EKYC
    const handleEkyc = async (data) => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const AadharNo = aadharno;
            const Pid = data || captureData;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                AadharNo,
                Pid,
                dmt_type
            };
            const response = await customerEkycApi(payload);
            setIsLoading(false);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setShowResponse(response?.data?.success);
                setOtpId(response?.data?.OTPRequestID);
                setKycId(response?.data?.KYCRequestID);
            }
            if (response?.data?.ResponseCode === 2) {
                globalNotifyError('Please resent the otp');
                setKycId(response?.data?.KYCRequestID);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR EKYC OTP VERIFY
    const handleEkycVErifyOtp = async () => {
        try {
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                OTPPin,
                OTPRequestId: OtpId,
                KYCRequestId: kycId,
                dmt_type
            };
            const response = await ekycOtpVerifyApi(payload);
            setIsLoading(false);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                navigate('/dmt/dmtTable');
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //API CALL FOR RESENT OTP
    const handleResentOtp = async () => {
        try {
            setOTPPin('');
            setIsLoading(true);
            const CustomerMobileNo = phone;
            const payload = {
                CustomerName,
                CustomerMobileNo,
                agentid,
                dmt_type
            };
            const response = await getCustomerOtpApi(payload);
            if (response?.data?.success) {
                globalNotifySuccess(response?.data?.message);
                setOtpId(response?.data?.OTPRequestID);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
            handleCatchError(error);
        }
    };
    //FUNCTION FOR VALIDATIONS///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const validateMobile = () => {
        const re = /^[0-9]{10}$/;
        if (!re.test(phone)) {
            setErrorMsg('Please enter a valid 10 digit mobile number');
        } else {
            setErrorMsg('');
        }
    };
    const validateName = () => {
        const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
        if (!re.test(CustomerName)) {
            setErrName('Please enter a valid full name');
        } else {
            setErrName('');
        }
    };
    const validateAadhar = () => {
        const re = /^\d{12}$/;
        if (!re.test(aadharno)) {
            setErrorAadhar('Please enter a valid Aadhaar card number');
        } else {
            setErrorAadhar('');
        }
    };
    const handleFirstNameChange = (event) => {
        const regex = /^[a-zA-Z\s]*$/;
        const firstName = event.target.value;
        if (regex.test(firstName) || firstName === '') {
            if (firstName.length <= 25) setCustomerName(firstName);
        }
    };
    const handleOtpChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 4);
        setOTPPin(amountValue);
    };
    const handleAadharChange = (e) => {
        let amountValue = e.target.value.replace(/[^\d.]/g, '');
        amountValue = amountValue.slice(0, 12);
        setAadharno(amountValue);
    };
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCaptureSuccess = (data) => {
        setShowFingerGif(false);
        // setPid(data);
        if (!data === false) {
            setCaptureData(data);
            handleEkyc(data);
        }
    };
    const handleFingerAnimation = () => {
        setShowFingerGif(true);
        const timeoutId = setTimeout(() => {
            handleCapture(onCaptureSuccess);
        }, 1000);
        return () => clearTimeout(timeoutId);
    };
    useEffect(() => {
        const checkIsMerchantOrBc = async () => {
            //CHECKING FOR  MERCHANT OR BC
            setIsCheckLoading(true);

            const payloadCheck = { agentid };
            const merchantBcCheckResponse = await checkMerchantOrBCAPI(payloadCheck);
            setIsCheckLoading(false);
            const agentType = merchantBcCheckResponse?.data?.agentType;
            const pendingStatus = merchantBcCheckResponse?.data?.isPending;
            setIsBcConvertPending(pendingStatus);

            if (agentType === 'Merchant' || pendingStatus || shouldshowEsign === 'true') {
                setMerchantModalOpen(true);

                return;
            }
            //CHECKING ENDS HERE
        };
        checkIsMerchantOrBc();
        setPhoneno(mobilefromSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {/* <<<<<<<Breadcrumb starts here>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                <Typography variant="h3">
                    <GlobalBackButton /> Create Customer
                </Typography>

                <Box sx={{ marginTop: '10px' }}>
                    <RouterLink to="/merchant-home" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <HomeOutlined />
                    </RouterLink>

                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <RouterLink to="/dmt/dmtTable" style={{ textDecoration: 'none', color: 'inherit' }}>
                        Domestic Money Transfer
                    </RouterLink>
                    <Typography component="span" style={{ margin: '0 10px' }}>
                        &gt;
                    </Typography>
                    <Typography component="span">Create Customer</Typography>
                </Box>
            </Box>
            {/* <<<<<<<Breadcrumb end here>>>>>>> */}
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    ...commonStyles,
                    borderRadius: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 5
                    }
                }}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                                required
                                label="Customer Name"
                                value={CustomerName}
                                onChange={handleFirstNameChange}
                                helperText={errName}
                                error={Boolean(errName)}
                                onBlur={validateName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={phone}
                                helperText={errorMsg}
                                error={Boolean(errorMsg)}
                                onBlur={validateMobile}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="outlined-basic"
                                label="Aadhaar Number"
                                variant="outlined"
                                fullWidth
                                required
                                value={aadharno}
                                onChange={handleAadharChange}
                                helperText={errorAadhar}
                                error={Boolean(errorAadhar)}
                                onBlur={validateAadhar}
                            />
                        </Grid>
                        {showResponse && (
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-basic"
                                    label="OTP Number"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={OTPPin}
                                    onChange={handleOtpChange}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                    <Link component="button" variant="body2" onClick={handleResentOtp}>
                                        Resend OTP
                                    </Link>
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        {!showResponse ? (
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                disabled={!CustomerName || !aadharno}
                                onClick={handleFingerAnimation}
                            >
                                {isLoading ? 'Loading' : 'Submit'}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                fullWidth
                                disabled={!CustomerName || !aadharno || !OTPPin}
                                onClick={handleEkycVErifyOtp}
                            >
                                {isLoading ? 'Loading' : 'Verify Customer'}
                            </Button>
                        )}
                    </Box>
                </Box>
                {info && <Alert message={info} type="info" showIcon style={{ marginTop: '20px' }} />}
            </Box>

            <ToastContainer />
            {merchantModalOpen && (
                <BcConvertModal
                    isOpen={merchantModalOpen}
                    handleclose={handleBcModalClose}
                    isPending={isBcConvertPending}
                    shouldshowEsign={shouldshowEsign}
                />
            )}
            <Spinner loading={isCheckLoading || isLoading} />
            {showFingerGif && <ViewCustomerAuth />}
        </div>
    );
}
