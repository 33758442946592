import { Box, Chip, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
const BalanceChip = ({ heading, value, mode, color = 'primary' }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 0.5,
                '&:hover': { cursor: 'pointer' }
            }}
        >
            <Chip
                className={mode === 'dark' ? 'goldenChip' : ''}
                color={color}
                size="small"
                sx={{
                    height: 'auto',
                    p: 0.5,
                    '&:hover': { cursor: 'pointer' }
                }}
                label={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%'
                        }}
                    >
                        <Typography variant="caption" sx={{ fontWeight: 'bold', color: '#fff' }}>
                            {heading}
                        </Typography>
                        <Divider sx={{ width: '100%', my: 0.25 }} />
                        <Typography sx={{ fontWeight: 'bold', color: '#fff' }} variant="body2">
                            ₹ {value || 0}
                        </Typography>
                    </Box>
                }
            />
        </Box>
    );
};

export default BalanceChip;
BalanceChip.propTypes = {
    heading: PropTypes.string,
    value: PropTypes.string,
    mode: PropTypes.string,
    color: PropTypes.string
};
