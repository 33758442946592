import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteRelationManager from 'routes/protectRouteRelationManager';

// Relation Manager Components
const DashboardRelationManager = Loadable(lazy(() => import('views/relation-manager/dashboard')));
const RelationManagerProfile = Loadable(lazy(() => import('views/relation-manager/profile')));

const SuperDistributorList = Loadable(lazy(() => import('views/relation-manager/superdistributor/list')));

const DistributorList = Loadable(lazy(() => import('views/relation-manager/distributor/list')));
const ZoneHeadForm = Loadable(lazy(() => import('views/relation-manager/zoneheads/add')));
const ZoneHeadList = Loadable(lazy(() => import('views/relation-manager/zoneheads/list')));
const StateHeadForm = Loadable(lazy(() => import('views/relation-manager/stateheads/add')));
const StateHeadList = Loadable(lazy(() => import('views/relation-manager/stateheads/list')));
const MiddleManager = Loadable(lazy(() => import('views/relation-manager/middlemanager/add')));
const MiddleManagerList = Loadable(lazy(() => import('views/relation-manager/middlemanager/list')));
const ManagerForm = Loadable(lazy(() => import('views/relation-manager/manager/add')));
const ManagerList = Loadable(lazy(() => import('views/relation-manager/manager/list')));
const CspForm = Loadable(lazy(() => import('views/relation-manager/csp/add')));
const CspList = Loadable(lazy(() => import('views/relation-manager/csp/list')));

const DailySalesMonitoring = Loadable(lazy(() => import('views/relation-manager/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/relation-manager/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/relation-manager/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/relation-manager/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/relation-manager/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/relation-manager/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/relation-manager/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/relation-manager/bc-txn-track/recharge')));
const ViewZoneHead = Loadable(lazy(() => import('views/relation-manager/viewzonehead')));
const ViewStateHead = Loadable(lazy(() => import('views/relation-manager/viewstatehead')));
const ViewMiddleManager = Loadable(lazy(() => import('views/relation-manager/viewmiddlemanager')));
const ViewManager = Loadable(lazy(() => import('views/relation-manager/viewmanager')));
const ViewCsp = Loadable(lazy(() => import('views/relation-manager/viewcsp')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/relation-manager/viewsuperdistributor')));
const SuperDistributorMapping = Loadable(lazy(() => import('views/relation-manager/superdistributor/mapping')));
const ViewDistributor = Loadable(lazy(() => import('views/relation-manager/viewdistributor')));
const ListMerchant = Loadable(lazy(() => import('views/relation-manager/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/relation-manager/viewMerchant')));

export const relationmanagerRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteRelationManager element={<DashboardRelationManager />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteRelationManager element={<RelationManagerProfile />} />
        },

        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteRelationManager element={<SuperDistributorList />} />
        },

        {
            path: 'listdistributor',
            element: <ProtectedRouteRelationManager element={<DistributorList />} />
        },
        {
            path: 'addzoneheads',
            element: <ProtectedRouteRelationManager element={<ZoneHeadForm />} />
        },
        {
            path: 'listzoneheads',
            element: <ProtectedRouteRelationManager element={<ZoneHeadList />} />
        },
        {
            path: 'addstateheads',
            element: <ProtectedRouteRelationManager element={<StateHeadForm />} />
        },
        {
            path: 'liststateheads',
            element: <ProtectedRouteRelationManager element={<StateHeadList />} />
        },
        {
            path: 'addmiddlemanager',
            element: <ProtectedRouteRelationManager element={<MiddleManager />} />
        },
        {
            path: 'listmiddlemanager',
            element: <ProtectedRouteRelationManager element={<MiddleManagerList />} />
        },
        {
            path: 'addmanager',
            element: <ProtectedRouteRelationManager element={<ManagerForm />} />
        },
        {
            path: 'listmanager',
            element: <ProtectedRouteRelationManager element={<ManagerList />} />
        },
        {
            path: 'addcsp',
            element: <ProtectedRouteRelationManager element={<CspForm />} />
        },
        {
            path: 'listcsp',
            element: <ProtectedRouteRelationManager element={<CspList />} />
        },

        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteRelationManager element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteRelationManager element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteRelationManager element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteRelationManager element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteRelationManager element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteRelationManager element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteRelationManager element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteRelationManager element={<RechargeTxnForm />} />
        },
        {
            path: 'ViewZoneHead',
            element: <ProtectedRouteRelationManager element={<ViewZoneHead />} />
        },
        {
            path: 'ViewStateHead',
            element: <ProtectedRouteRelationManager element={<ViewStateHead />} />
        },
        {
            path: 'ViewMiddleManager',
            element: <ProtectedRouteRelationManager element={<ViewMiddleManager />} />
        },
        {
            path: 'ViewManager',
            element: <ProtectedRouteRelationManager element={<ViewManager />} />
        },
        {
            path: 'ViewCsp',
            element: <ProtectedRouteRelationManager element={<ViewCsp />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteRelationManager element={<ViewSuperdistributor />} />
        },
        {
            path: 'superdistributormapping',
            element: <ProtectedRouteRelationManager element={<SuperDistributorMapping />} />
        },
        {
            path: 'ViewDistributor',
            element: <ProtectedRouteRelationManager element={<ViewDistributor />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteRelationManager element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteRelationManager element={<ViewMerchant />} />
        }
    ]
};
