// NearbyAgents.jsx
import { useState } from 'react';
import {
    Container,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './BcaVerify.module.css';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { agentsListToVerifyApi, getCityApi, getDistrictApi, getStateApi } from 'utils/API/Distributor APIs/DistributorAPiCalls';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
const breadcrumbRoutes = [{ label: 'Nearby Agents', path: '' }];

const NearbyAgents = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        state: '',
        district: '',
        city: ''
    });
    const [filterText, setFilterText] = useState({
        agentCode: '',
        mobileNumber: ''
    });
    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedState, setSelectedState] = useState({});

    //pagination
    const [page, setPage] = useState(0); // Current page (zero-based index)
    const [pageSize, setPageSize] = useState(100);
    const loginLatitude = localStorage.getItem('loginLatitude');
    const loginLongitude = localStorage.getItem('loginLongitude');
    // const handleRedirect = (latitude, longitude) => {
    //     const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    //     window.open(googleMapsUrl, '_blank'); // Open in a new tab
    // };
    const handleRedirect = (latitude, longitude) => {
        const userAgent = navigator.userAgent;
        const isAndroid = /Android/i.test(userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

        if (isAndroid) {
            const androidMapsUrl = `geo:${latitude},${longitude}?q=${latitude},${longitude}`;
            window.location.href = androidMapsUrl;
        } else if (isIOS) {
            const iosMapsUrl = `https://maps.apple.com/?q=${latitude},${longitude}`;
            window.location.href = iosMapsUrl;
        } else {
            const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(googleMapsUrl, '_blank');
        }
    };
    const columns = [
        { field: 'agent_code', headerName: 'Agent Code', width: 130 },
        { field: 'full_name', headerName: 'Agent Name', width: 190 },
        { field: 'phone', headerName: 'Phone Number', width: 150 },
        {
            field: 'distance',
            headerName: 'Distance',
            width: 130,
            valueGetter: (params) => {
                return `${params.value} Km `;
            }
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<LocationOnIcon />}
                    className={styles.verifyButton}
                    onClick={() => handleRedirect(params.row.latitude, params.row.longitude)}
                >
                    View Location
                </Button>
            )
        },
        {
            field: 'verify',
            headerName: '',
            width: 130,
            renderCell: (params) => {
                if (params.row.physical_verification_status === 'approved') {
                    return (
                        <Typography variant="body2" color="success.main">
                            Approved
                        </Typography>
                    );
                } else if (params.row.physical_verification_status === 'pending') {
                    return (
                        <Typography variant="body2" color="warning.main">
                            Pending
                        </Typography>
                    );
                } else {
                    return (
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<VerifiedIcon />}
                            className={styles.verifyButton}
                            onClick={() => handleVerify(params.row)}
                        >
                            Verify
                        </Button>
                    );
                }
            }
        }
    ];

    const handleFilterChange = (event) => {
        const eventName = event.target.name;
        const eventvalue = event.target.value;
        if (eventName === 'state') {
            setSelectedState(stateList?.filter((item) => item.id === eventvalue)[0]);
            handleDistricts(eventvalue.toString());
        } else if (eventName === 'district') {
            handleCities(eventvalue.toString());
        }
        if (eventName === 'agentCode' || eventName === 'mobileNumber') {
            setFilterText({
                ...filterText,
                [eventName]: eventvalue.toString()
            });
        } else {
            setFilters({
                ...filters,
                [eventName]: eventvalue.toString()
            });
        }
    };

    const handleVerify = (data) => {
        navigate('/employee/verifyBcAgent', { state: { data } });
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const processAgentArray = (data) => {
        return data.map((item, index) => {
            let distanceValue;

            if (isNaN(item.distance) || item.distance === null) {
                distanceValue = 'Unavailable';
            } else {
                distanceValue = parseFloat(item.distance).toFixed(2);
            }

            return {
                ...item,
                id: item.id || index,
                distance: distanceValue, // Set distance to "Unavailable" if NaN or null, otherwise format to 2 decimals
                full_name: `${item.agname || ''} ${item.mname || ''} ${item.lname || ''}`.trim(), // Merge name fields
                created_at: formatDate(item.created_at) // Format date
            };
        });
    };

    //api calls>>>
    const handleStates = async () => {
        const response = await getStateApi();
        if (response?.status === 200) {
            const list = response?.data?.dmt_states;
            setStateList(list);
        }
    };
    const handleDistricts = async (id) => {
        const payload = { id };
        const response = await getDistrictApi(payload);
        if (response?.status === 200) {
            const list = response?.data?.district;
            setDistrictList(list);
        }
    };
    const handleCities = async (id) => {
        const payload = { id };
        const response = await getCityApi(payload);
        if (response?.status === 200) {
            const list = response?.data?.city;
            setCityList(list);
        }
    };
    const handleAgentList = async () => {
        setLoading(true);
        const payload = {
            latitude: loginLatitude,
            longitude: loginLongitude,
            state: selectedState?.statecode,
            district: filters.district,
            city: filters.city,
            agentcode: filterText.agentCode,
            phone: filterText.mobileNumber,
            pageNumber: page,
            length: pageSize
        };
        const response = await agentsListToVerifyApi(payload);
        if (response?.status === 200) {
            const list = response?.data?.data;
            const modifiedData = processAgentArray(list);
            setAgentList(modifiedData);
        }
        setLoading(false);
    };
    useEffect(() => {
        handleStates();
    }, []);
    useEffect(() => {
        handleAgentList();
        // eslint-disable-next-line
    }, [filters]);
    return (
        <>
            <BreadcrumbComponent routes={breadcrumbRoutes} />
            <Container maxWidth="lg" className={styles.container}>
                <Paper elevation={3} className={styles.paper}>
                    <Accordion className={styles.accordion}>
                        <AccordionSummary
                            className={styles.accordionHeader}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="filters-content"
                            id="filters-header"
                        >
                            <Typography className={styles.filterTitle}>Filter Agents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={styles.filtersContainer}>
                                <FormControl className={styles.formControl}>
                                    <InputLabel>State</InputLabel>
                                    <Select name="state" value={filters.state} onChange={handleFilterChange} label="State">
                                        {stateList?.map((item) => (
                                            <MenuItem value={item.id}>{item?.state}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={styles.formControl}>
                                    <InputLabel>District</InputLabel>
                                    <Select name="district" value={filters.district} onChange={handleFilterChange} label="District">
                                        {districtList?.map((item) => (
                                            <MenuItem value={item.id}>{item?.district}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl className={styles.formControl}>
                                    <InputLabel>City</InputLabel>
                                    <Select name="city" value={filters.city} onChange={handleFilterChange} label="City">
                                        {cityList?.map((item) => (
                                            <MenuItem value={item.city}>{item.city}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    label="Agent Code"
                                    name="agentCode"
                                    value={filterText.agentCode}
                                    onChange={handleFilterChange}
                                    className={styles.textField}
                                    placeholder="Enter complete code"
                                />

                                <TextField
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    value={filterText.mobileNumber}
                                    onChange={handleFilterChange}
                                    placeholder="Enter full mobile number"
                                    className={styles.textField}
                                />
                                <Button variant="outlined" onClick={() => handleAgentList()}>
                                    Search
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Box className={styles.tableContainer}>
                        <DataGrid
                            rows={agentList}
                            columns={columns}
                            rowsPerPageOptions={[10, 25, 50]}
                            disableSelectionOnClick
                            className={styles.dataGrid}
                            rowCount={agentList.length} // Total number of rows for backend pagination
                            pageSize={pageSize}
                            page={page}
                            paginationMode="server" // Enable server-side pagination
                            onPageChange={(newPage) => setPage(newPage)} // Update page number
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Update page size
                            loading={loading} // Show loading indicator while fetching data
                            pagination // Enable pagination
                        />
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default NearbyAgents;
