import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteStatehead from 'routes/protectedRouteStatehead';

const DashboardStatehead = Loadable(lazy(() => import('views/stateHeadDashboard/dashboard')));
const RelationManagerProfile = Loadable(lazy(() => import('views/stateHeadDashboard/profile')));
const SuperDistributorForm = Loadable(lazy(() => import('views/stateHeadDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/stateHeadDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/stateHeadDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/stateHeadDashboard/distributor/add')));
const SuperDistributorMapping = Loadable(lazy(() => import('views/stateHeadDashboard/superdistributor/mapping')));
const MiddleManager = Loadable(lazy(() => import('views/stateHeadDashboard/middlemanager/add')));
const MiddleManagerList = Loadable(lazy(() => import('views/stateHeadDashboard/middlemanager/list')));
const ManagerForm = Loadable(lazy(() => import('views/stateHeadDashboard/manager/add')));
const ManagerList = Loadable(lazy(() => import('views/stateHeadDashboard/manager/list')));
const CspForm = Loadable(lazy(() => import('views/stateHeadDashboard/csp/add')));
const CspList = Loadable(lazy(() => import('views/stateHeadDashboard/csp/list')));
const ViewMiddleManager = Loadable(lazy(() => import('views/stateHeadDashboard/viewmiddlemanager')));
const ViewManager = Loadable(lazy(() => import('views/stateHeadDashboard/viewmanager')));
const ViewCsp = Loadable(lazy(() => import('views/stateHeadDashboard/viewcsp')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/stateHeadDashboard/viewsuperdistributor')));
const ViewDistributor = Loadable(lazy(() => import('views/stateHeadDashboard/viewdistributor')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/stateHeadDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/stateHeadDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/stateHeadDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/stateHeadDashboard/viewMerchant')));

export const stateheadRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteStatehead element={<DashboardStatehead />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteStatehead element={<RelationManagerProfile />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteStatehead element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteStatehead element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteStatehead element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteStatehead element={<DistributorForm />} />
        },
        {
            path: 'superdistributormapping',
            element: <ProtectedRouteStatehead element={<SuperDistributorMapping />} />
        },
        {
            path: 'addmiddlemanager',
            element: <ProtectedRouteStatehead element={<MiddleManager />} />
        },
        {
            path: 'listmiddlemanager',
            element: <ProtectedRouteStatehead element={<MiddleManagerList />} />
        },
        {
            path: 'addmanager',
            element: <ProtectedRouteStatehead element={<ManagerForm />} />
        },
        {
            path: 'listmanager',
            element: <ProtectedRouteStatehead element={<ManagerList />} />
        },
        {
            path: 'addcsp',
            element: <ProtectedRouteStatehead element={<CspForm />} />
        },
        {
            path: 'listcsp',
            element: <ProtectedRouteStatehead element={<CspList />} />
        },
        {
            path: 'ViewMiddleManager',
            element: <ProtectedRouteStatehead element={<ViewMiddleManager />} />
        },
        {
            path: 'ViewManager',
            element: <ProtectedRouteStatehead element={<ViewManager />} />
        },
        {
            path: 'ViewCsp',
            element: <ProtectedRouteStatehead element={<ViewCsp />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteStatehead element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteStatehead element={<ViewDistributor />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteStatehead element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteStatehead element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteStatehead element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteStatehead element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteStatehead element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteStatehead element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteStatehead element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteStatehead element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteStatehead element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteStatehead element={<ViewMerchant />} />
        }
    ]
};
