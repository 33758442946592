import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteMiddlemanager from 'routes/protectedRouteMiddlemanager';

const DashboardMiddlemanager = Loadable(lazy(() => import('views/middleManagerDashboard/dashboard')));
const SuperDistributorForm = Loadable(lazy(() => import('views/middleManagerDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/middleManagerDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/middleManagerDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/middleManagerDashboard/distributor/add')));
const ManagerForm = Loadable(lazy(() => import('views/middleManagerDashboard/manager/add')));
const ManagerList = Loadable(lazy(() => import('views/middleManagerDashboard/manager/list')));
const CspForm = Loadable(lazy(() => import('views/middleManagerDashboard/csp/add')));
const CspList = Loadable(lazy(() => import('views/middleManagerDashboard/csp/list')));
const RelationManagerProfile = Loadable(lazy(() => import('views/middleManagerDashboard/profile')));
const ViewManager = Loadable(lazy(() => import('views/middleManagerDashboard/viewmanager')));
const ViewCsp = Loadable(lazy(() => import('views/middleManagerDashboard/viewcsp')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/middleManagerDashboard/viewsuperdistributor')));
const ViewDistributor = Loadable(lazy(() => import('views/middleManagerDashboard/viewdistributor')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/middleManagerDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/middleManagerDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/middleManagerDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/middleManagerDashboard/viewMerchant')));

export const middlemanagerRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteMiddlemanager element={<DashboardMiddlemanager />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteMiddlemanager element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteMiddlemanager element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteMiddlemanager element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteMiddlemanager element={<DistributorForm />} />
        },
        {
            path: 'addmanager',
            element: <ProtectedRouteMiddlemanager element={<ManagerForm />} />
        },
        {
            path: 'listmanager',
            element: <ProtectedRouteMiddlemanager element={<ManagerList />} />
        },
        {
            path: 'addcsp',
            element: <ProtectedRouteMiddlemanager element={<CspForm />} />
        },
        {
            path: 'listcsp',
            element: <ProtectedRouteMiddlemanager element={<CspList />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteMiddlemanager element={<RelationManagerProfile />} />
        },
        {
            path: 'ViewManager',
            element: <ProtectedRouteMiddlemanager element={<ViewManager />} />
        },
        {
            path: 'ViewCsp',
            element: <ProtectedRouteMiddlemanager element={<ViewCsp />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteMiddlemanager element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteMiddlemanager element={<ViewDistributor />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteMiddlemanager element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteMiddlemanager element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteMiddlemanager element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteMiddlemanager element={<ViewMerchant />} />
        }
    ]
};
