import { useState, useEffect } from 'react';
import { Card, Box, Typography, LinearProgress, Fade } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { todaySummaryApi } from 'utils/API/Merchant APIs/MerchantApiCalls';
import { handleCatchError } from 'utils/CatchHandler';
/* eslint-disable */
const SummaryButton = () => {
    const [todayData, setTodayData] = useState({});
    const [currentKey, setCurrentKey] = useState('dmt');
    const [visible, setVisible] = useState(true);
    const agentid = localStorage.getItem('id');
    const mode = useSelector((state) => state.theme.mode);
    const navigate = useNavigate();

    useEffect(() => {
        const handleTodaySummary = async () => {
            try {
                const payload = { agentid };
                const response = await todaySummaryApi(payload);
                setTodayData(response?.data?.data || {});
            } catch (error) {
                handleCatchError(error);
            }
        };

        handleTodaySummary();
    }, []);

    useEffect(() => {
        const keys = Object.keys(todayData);
        if (keys.length === 0) return;

        let index = 0;
        const interval = setInterval(() => {
            setVisible(false);
            setTimeout(() => {
                setCurrentKey(keys[index]);
                setVisible(true);
                index = (index + 1) % keys.length;
            }, 300);
        }, 3000);

        return () => clearInterval(interval);
    }, [todayData]);

    const formatKeyName = (key) => key.toUpperCase();

    const handleClick = () => {
        navigate('/profile-summary');
    };

    return (
        <Card
            sx={{
                padding: '0.5rem',
                maxWidth: 400, // Increased width from 300 to 400
                width: '10%', // Makes it responsive
                borderRadius: '12px',
                marginRight: '27px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                background: 'linear-gradient(135deg,rgb(25, 26, 27), #1976D2)',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
                '&:hover': { transform: 'scale(1.05)' }
            }}
            onClick={handleClick}
        >
            <Box display="flex" alignItems="center">
                <Box
                    sx={{
                        backgroundColor: '#10B981',
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <TrendingUpIcon sx={{ color: '#fff', fontSize: '0.7rem' }} />
                </Box>
                <Fade in={visible} timeout={300}>
                    <Typography sx={{ marginLeft: '0.4rem', color: '#fff', fontSize: '13px', fontWeight: 600 }}>
                        {formatKeyName(currentKey)}
                    </Typography>
                </Fade>
            </Box>

            <Box sx={{ marginTop: '0.4rem' }}>
                <Fade in={visible} timeout={300}>
                    <Typography sx={{ color: '#fff', fontSize: '.7rem', fontWeight: 700, textAlign: 'left' }}>
                        ₹{todayData[currentKey] || '0.00'}
                    </Typography>
                </Fade>
                <LinearProgress
                    variant="determinate"
                    value={parseFloat(todayData[currentKey]) || 0}
                    sx={{
                        backgroundColor: '#E5E7EB',
                        height: '0.1rem',
                        borderRadius: '0.2rem',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#10B981'
                        }
                    }}
                />
            </Box>
        </Card>
    );
};

export default SummaryButton;
