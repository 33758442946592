import Dashboard from './dashboard';
// ==============================|| MENU ITEMS ||============================== //
const verificationDashboard = localStorage.getItem('verificationDashboard');

let filteredMenu = Dashboard;
if (verificationDashboard !== 'true') {
    const excludedIds = ['BC_Phy_Verification'];

    filteredMenu = {
        ...filteredMenu, // Use the already filteredMenu from the previous condition (if applied)
        children: filteredMenu.children.filter((child) => !excludedIds.includes(child.id))
    };
}
const EmployeeMenuItems = {
    items: [filteredMenu]
};

export default EmployeeMenuItems;
