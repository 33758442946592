import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { listMerchants } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { Box, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
const breadCrumbRoutes = [{ label: 'Branch Transactions', path: '' }];
const BranchTransactions = () => {
    const location = useLocation();
    const today = dayjs().format('YYYY/MM/DD');
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(10);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const id = location?.state?.params?.id;

    //handler functions>>>
    const handlePageSizeChange = (params) => {
        setPage(params.page + 1);
        setPageSize(params?.pageSize);
    };
    const handleFromDateChange = (newValue) => {
        setFromDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };
    //api calls>>
    const getTransactionHistory = async () => {
        setLoading(true);
        const payload = { id, start: page, length: rowCount, fromdate: fromDate, todate: toDate };
        const response = await listMerchants(payload);
        if (response?.status === 200) {
            const data = response?.data?.data;
            setRowCount(data?.length);
            setTransactions(data);
        }
        setLoading(false);
    };

    useEffect(() => {
        getTransactionHistory();
        // eslint-disable-next-line
    }, [page, pageSize, fromDate, toDate]);
    const columns = [
        {
            field: 'created_at',
            headerName: 'Date',
            width: 190,
            valueGetter: (params) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Kolkata' };
                const indianDate = new Date(params.value).toLocaleDateString('en-GB', options);
                const indianTime = new Date(params.value).toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' });
                return `${indianDate} ${' '}${indianTime}`;
            }
        },
        { field: 'description', headerName: 'Particular', width: 350 },
        {
            field: 'isCredit',
            headerName: 'Type',
            width: 100,
            cellClassName: (params) => (Number(params.value) === 'Credit' ? 'greenCell' : 'redCell')
        },
        { field: 'amount', headerName: 'Amount', width: 100 },
        { field: 'balance', headerName: 'Balance', width: 100 }
    ];

    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: 2,
                        marginBottom: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <DatePicker
                            label="From Date"
                            value={fromDate ? dayjs(fromDate, 'YYYY/MM/DD') : null}
                            onChange={handleFromDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate ? dayjs(toDate, 'YYYY/MM/DD') : null}
                            onChange={handleToDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Box>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={transactions}
                            columns={columns}
                            pagination
                            getRowId={(row) => row.id}
                            paginationMode="server"
                            rowCount={rowCount}
                            pageSizeOptions={[5, 10, 20]}
                            pageSize={pageSize}
                            onPageChange={(newPage) => setPage(newPage)}
                            onPaginationModelChange={(params) => handlePageSizeChange(params)}
                            loading={loading}
                        />
                    </div>
                </Box>
            </LocalizationProvider>
        </>
    );
};

export default BranchTransactions;
