import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ProtectedRouteDistributor from '../ProtectRouteDistributor';

//Distributor UI files imports>>>>>>>>>
const DashboardDistributor = Loadable(lazy(() => import('views/Distributor/Dashboard')));
const Profile = Loadable(lazy(() => import('views/Distributor/Profile')));
const WalletRequest = Loadable(lazy(() => import('views/Distributor/WalletRequest')));
const BankDetails = Loadable(lazy(() => import('views/Distributor/BankDetails')));
const ApproveWallet = Loadable(lazy(() => import('views/Distributor/ApproveWallet')));
const Merchants = Loadable(lazy(() => import('views/Distributor/Merchants')));
const AddMerchants = Loadable(lazy(() => import('views/Distributor/AddMerchants')));
const ViewMerchants = Loadable(lazy(() => import('views/Distributor/ViewMerchants')));
const TransactionMerchant = Loadable(lazy(() => import('views/Distributor/TransactionMerchant')));
const EditMerchant = Loadable(lazy(() => import('views/Distributor/EditMerchant')));
const TransactionHistory = Loadable(lazy(() => import('views/Distributor/TransactionHistory')));
const CreateEmployee = Loadable(lazy(() => import('views/Distributor/CreateEmployee')));
const ListEmployee = Loadable(lazy(() => import('views/Distributor/ListEmployee')));
const QronboardDist = Loadable(lazy(() => import('views/Distributor/merchantTable/QrOnboard')));
const PosOnboardDist = Loadable(lazy(() => import('views/Distributor/merchantTable/PosOnboard')));
const OnBoardingStatusDist = Loadable(lazy(() => import('views/Distributor/merchantTable/OnboardingStatus')));
const QrOnboarding = Loadable(lazy(() => import('views/Distributor/Onboarding/QrOnboarding')));
const PosOnboarding = Loadable(lazy(() => import('views/Distributor/Onboarding/PosOnboarding')));
const BcAgentActivation = Loadable(lazy(() => import('views/Distributor/Onboarding/BcAgentActivation')));
const Map = Loadable(lazy(() => import('views/Distributor/merchantTable/Map')));
const Tm = Loadable(lazy(() => import('views/Distributor/TM')));
const ListTm = Loadable(lazy(() => import('views/Distributor/ListTm')));
const Tl = Loadable(lazy(() => import('views/Distributor/TL')));
const ListTL = Loadable(lazy(() => import('views/Distributor/ListTL')));
const Onboarding = Loadable(lazy(() => import('views/Distributor/Onboarding/Onboarding')));
const SubdistributorInDist = Loadable(lazy(() => import('views/Distributor/Subdistributors')));
const MyTrasactionsDist = Loadable(lazy(() => import('views/Distributor/MyTransactions')));
const CreditToSuper = Loadable(lazy(() => import('views/Distributor/CreditToSuperDist')));
const CreditToAgent = Loadable(lazy(() => import('views/Distributor/Credit')));
const SoundBoxDist = Loadable(lazy(() => import('views/Distributor/SoundBox')));
const IncentiveForm = Loadable(lazy(() => import('views/Distributor/IncentiveEligibleReport')));
const All_Merchants = Loadable(lazy(() => import('views/Distributor/AllMerchants')));
const All_bc = Loadable(lazy(() => import('views/Distributor/AllBc')));
const MerchantViewDist = Loadable(lazy(() => import('views/Distributor/ViewMerchantsAll')));
const TransactionMerchantsDist = Loadable(lazy(() => import('views/Distributor/TransactionMerchants')));
const RedirectBcDashboard = Loadable(lazy(() => import('views/super-distributor/RedirectBcDashboard')));
const InventoryAddDeviceRequest = Loadable(lazy(() => import('views/Inventory Management/Pages/Distributor/AddDeviceRequest')));
const InventoryListDeviceRequest = Loadable(lazy(() => import('views/Inventory Management/Pages/Distributor/ListDeviceRequest')));
const InventoryListReceivedRequests = Loadable(lazy(() => import('views/Inventory Management/Pages/Distributor/ListReceivedRequests')));
const InventoryListDispatchedOrders = Loadable(lazy(() => import('views/Inventory Management/Pages/Distributor/ListDispatchedOrders')));
const PosTransactions = Loadable(lazy(() => import('views/Distributor/MerchantTxnTrack/Pos')));
const QrTransactions = Loadable(lazy(() => import('views/Distributor/MerchantTxnTrack/Qr')));
const DmtTransactions = Loadable(lazy(() => import('views/Distributor/BcTxnTrack/Dmt')));
const AepsTransactions = Loadable(lazy(() => import('views/Distributor/BcTxnTrack/Aeps')));
const BbpsTransactions = Loadable(lazy(() => import('views/Distributor/BcTxnTrack/Bbps')));
const RechargeTransactions = Loadable(lazy(() => import('views/Distributor/BcTxnTrack/Recharge')));
const MatmTransactions = Loadable(lazy(() => import('views/Distributor/BcTxnTrack/Matm')));
const MapEmployee = Loadable(lazy(() => import('views/Distributor/EmployeeMapping/Map')));
const EmployeeOnboarding = Loadable(lazy(() => import('views/Distributor/EmployeeOnboarding')));
const TmOnboarding = Loadable(lazy(() => import('views/Distributor/TmOnboarding')));
const TlOnboarding = Loadable(lazy(() => import('views/Distributor/TlOnboarding')));
export const DistributorRoutes = {
    path: '',
    // element: <AdminLayout />,
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteDistributor element={<DashboardDistributor />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteDistributor element={<Profile />} />
        },
        {
            path: 'walletrequest',
            element: <ProtectedRouteDistributor element={<WalletRequest />} />
        },
        {
            path: 'bankdetails',
            element: <ProtectedRouteDistributor element={<BankDetails />} />
        },
        {
            path: 'approvewallet',
            element: <ProtectedRouteDistributor element={<ApproveWallet />} />
        },
        {
            path: 'merchants',
            element: <ProtectedRouteDistributor element={<Merchants />} />
        },
        {
            path: 'addmerchants',
            element: <ProtectedRouteDistributor element={<AddMerchants />} />
        },
        {
            path: 'view-merchants',
            element: <ProtectedRouteDistributor element={<ViewMerchants />} />
        },
        {
            path: 'transaction-merchant',
            element: <ProtectedRouteDistributor element={<TransactionMerchant />} />
        },
        {
            path: 'indexeditmerchants',
            element: <ProtectedRouteDistributor element={<EditMerchant />} />
        },
        {
            path: 'transaction-history',
            element: <ProtectedRouteDistributor element={<TransactionHistory />} />
        },
        {
            path: 'createemployee',
            element: <ProtectedRouteDistributor element={<CreateEmployee />} />
        },
        {
            path: 'listemployee',
            element: <ProtectedRouteDistributor element={<ListEmployee />} />
        },
        {
            path: 'qronboard',
            element: <ProtectedRouteDistributor element={<QronboardDist />} />
        },
        {
            path: 'posonboard',
            element: <ProtectedRouteDistributor element={<PosOnboardDist />} />
        },
        {
            path: 'status',
            element: <ProtectedRouteDistributor element={<OnBoardingStatusDist />} />
        },
        {
            path: 'tm',
            element: <ProtectedRouteDistributor element={<Tm />} />
        },
        {
            path: 'listtm',
            element: <ProtectedRouteDistributor element={<ListTm />} />
        },
        {
            path: 'tl',
            element: <ProtectedRouteDistributor element={<Tl />} />
        },
        {
            path: 'listtl',
            element: <ProtectedRouteDistributor element={<ListTL />} />
        },
        {
            path: 'onboarding',
            element: <ProtectedRouteDistributor element={<Onboarding />} />
        },
        {
            path: 'IncentiveReport',
            element: <ProtectedRouteDistributor element={<IncentiveForm />} />
        },
        {
            path: 'qr-onboarding',
            element: <ProtectedRouteDistributor element={<QrOnboarding />} />
        },
        {
            path: 'pos-onboarding',
            element: <ProtectedRouteDistributor element={<PosOnboarding />} />
        },
        {
            path: 'bcactivation',
            element: <ProtectedRouteDistributor element={<BcAgentActivation />} />
        },
        {
            path: 'map',
            element: <ProtectedRouteDistributor element={<Map />} />
        },
        {
            path: 'all-merchants',
            element: <ProtectedRouteDistributor element={<All_Merchants />} />
        },
        {
            path: 'all-bc',
            element: <ProtectedRouteDistributor element={<All_bc />} />
        },
        {
            path: 'merchant-view',
            element: <ProtectedRouteDistributor element={<MerchantViewDist />} />
        },
        {
            path: 'transaction-merchants',
            element: <ProtectedRouteDistributor element={<TransactionMerchantsDist />} />
        },
        {
            path: 'manage-subdistributor',
            element: <ProtectedRouteDistributor element={<SubdistributorInDist />} />
        },
        {
            path: 'my-transactions',
            element: <ProtectedRouteDistributor element={<MyTrasactionsDist />} />
        },
        {
            path: 'credit-tosuperdist',
            element: <ProtectedRouteDistributor element={<CreditToSuper />} />
        },
        {
            path: 'credit-toagent',
            element: <ProtectedRouteDistributor element={<CreditToAgent />} />
        },
        { path: 'bc-dashboard-redirection', element: <ProtectedRouteDistributor element={<RedirectBcDashboard />} /> },
        { path: 'soundboxlist', element: <ProtectedRouteDistributor element={<SoundBoxDist />} /> },
        { path: 'inventory/add-device-request', element: <ProtectedRouteDistributor element={<InventoryAddDeviceRequest />} /> },
        { path: 'inventory/list-device-request', element: <ProtectedRouteDistributor element={<InventoryListDeviceRequest />} /> },
        { path: 'inventory/list-request-received', element: <ProtectedRouteDistributor element={<InventoryListReceivedRequests />} /> },
        { path: 'inventory/list-dispatched-orders', element: <ProtectedRouteDistributor element={<InventoryListDispatchedOrders />} /> },
        { path: 'soundboxlist', element: <ProtectedRouteDistributor element={<SoundBoxDist />} /> },
        { path: 'pos-transactions', element: <ProtectedRouteDistributor element={<PosTransactions />} /> },
        { path: 'qr-transactions', element: <ProtectedRouteDistributor element={<QrTransactions />} /> },
        { path: 'dmt-transactions', element: <ProtectedRouteDistributor element={<DmtTransactions />} /> },
        { path: 'aeps-transactions', element: <ProtectedRouteDistributor element={<AepsTransactions />} /> },
        { path: 'matm-transactions', element: <ProtectedRouteDistributor element={<MatmTransactions />} /> },
        { path: 'recharge-transactions', element: <ProtectedRouteDistributor element={<RechargeTransactions />} /> },
        { path: 'bbps-transactions', element: <ProtectedRouteDistributor element={<BbpsTransactions />} /> },
        { path: 'employee-map', element: <ProtectedRouteDistributor element={<MapEmployee />} /> },
        { path: 'Employee-onboarding', element: <ProtectedRouteDistributor element={<EmployeeOnboarding />} /> },
        { path: 'tm-onboarding', element: <ProtectedRouteDistributor element={<TmOnboarding />} /> },
        { path: 'tl-onboarding', element: <ProtectedRouteDistributor element={<TlOnboarding />} /> }
    ]
};
