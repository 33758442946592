/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons11',
            title: 'Dashboard',
            type: 'item',
            url: 'relation-manager/dashboard',
            breadcrumbs: true
        },
        {
            id: 'icons2231222',
            title: 'Super Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53221103',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listsuperdistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322110909',
                    title: 'Mapping',
                    type: 'item',
                    url: '/relation-manager/superdistributormapping',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'tabler-icons20',
        //     title: 'Profile',
        //     type: 'item',
        //     url: 'relation-manager/profile',
        //     breadcrumbs: true
        // },
        {
            id: 'icons2231227',
            title: 'Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53221189',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listdistributor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231226',
            title: 'Zone Heads',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31210122',
                    title: 'Add',
                    type: 'item',
                    url: 'relation-manager/addzoneheads',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53221133',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listzoneheads',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231225',
            title: 'State Heads',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121017',
                    title: 'Add',
                    type: 'item',
                    url: 'relation-manager/addstateheads',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322118',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/liststateheads',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231224',
            title: 'Middle Manager ',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121015',
                    title: 'Add',
                    type: 'item',
                    url: 'relation-manager/addmiddlemanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322116',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listmiddlemanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231223',
            title: 'Manager',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121013',
                    title: 'Add',
                    type: 'item',
                    url: 'relation-manager/addmanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322114',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listmanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223122',
            title: 'Csp/Sanchalak',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121010',
                    title: 'Add',
                    type: 'item',
                    url: 'relation-manager/addcsp',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322112',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listcsp',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223122564',
            title: 'Merchant',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons532211009',
                    title: 'List',
                    type: 'item',
                    url: '/relation-manager/listmerchant',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'tabler-icons0013355',
            title: 'Daily Sales Monitoring',
            type: 'item',
            url: 'relation-manager/dailySalesMonitoring',
            breadcrumbs: true
        },
        {
            id: 'icons223122322',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31210112',
                    title: 'POS',
                    type: 'item',
                    url: 'relation-manager/posTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322111',
                    title: 'QR',
                    type: 'item',
                    url: '/relation-manager/qrTxnTrack',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231223567',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312101009',
                    title: 'DMT',
                    type: 'item',
                    url: 'relation-manager/dmtTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532211008',
                    title: 'BBPS',
                    type: 'item',
                    url: '/relation-manager/bbpsTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532211001',
                    title: 'RECHARGE',
                    type: 'item',
                    url: '/relation-manager/rechargeTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532211002',
                    title: 'MATM',
                    type: 'item',
                    url: '/relation-manager/matmTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532211003',
                    title: 'AEPS',
                    type: 'item',
                    url: '/relation-manager/aepsTxnTrack',
                    breadcrumbs: false
                }
            ]
        }
        // Temporary hide as per the requirement from product team
        // {
        //     id: 'tabler-icons0033',
        //     title: 'Counts',
        //     type: 'item',
        //     url: 'super-distributor/countlist',
        //     breadcrumbs: true
        // },
    ]
};

export default Dashboard;
