import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import ProtectedRouteSuperDistributorEmployee from '../ProtectedSuperdistributorEmployee';

const Dashboard = Loadable(lazy(() => import('views/Superdistributor Employee/Dashboard')));
const ListBatches = Loadable(lazy(() => import('views/Superdistributor Employee/List Batches/ListBatchesPending')));
const ListBatchesSuccess = Loadable(lazy(() => import('views/Superdistributor Employee/List Batches/ListBatchesSuccess')));
const ListBatchesFailed = Loadable(lazy(() => import('views/Superdistributor Employee/List Batches/ListBatchesFailed')));
const ListEmployeeTransactions = Loadable(lazy(() => import('views/Superdistributor Employee/ListEmployeeTransactions')));
const Profile = Loadable(lazy(() => import('views/Superdistributor Employee/Profile')));
const EditProfile = Loadable(lazy(() => import('views/Superdistributor Employee/EditProfile')));
export const SuperdistributorEmployeeRoutes = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteSuperDistributorEmployee element={<Dashboard />} />
        },
        {
            path: 'list-batches',
            element: <ProtectedRouteSuperDistributorEmployee element={<ListBatches />} />
        },
        {
            path: 'list-batches-success',
            element: <ProtectedRouteSuperDistributorEmployee element={<ListBatchesSuccess />} />
        },
        {
            path: 'list-batches-failed',
            element: <ProtectedRouteSuperDistributorEmployee element={<ListBatchesFailed />} />
        },
        {
            path: 'list-employee-transactions',
            element: <ProtectedRouteSuperDistributorEmployee element={<ListEmployeeTransactions />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteSuperDistributorEmployee element={<Profile />} />
        },
        {
            path: 'edit-profile',
            element: <ProtectedRouteSuperDistributorEmployee element={<EditProfile />} />
        }
    ]
};
