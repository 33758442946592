/* eslint-disable */

import BankingServices from './BankingServices';
import UtilityBillsAndEMI from './utilityBillsAndEMI';
import CommonService from './CommonService';
import Reports from './Reports';
import Pancard from './Pancard';
import Wallet from './Wallets';
import dashboard from './dashboard';
import RDServices from './RDServices';
import CustomerSupport from './CustomerSupport';
import HelpLine from './HelpLine';
import MerchantEmployee from './MerchantEmployee';
import Batches from './Batches';
import Ledger from './Ledger';
import BankAccount from './BankAccount';
// ==============================|| MENU ITEMS ||============================== //
const currentDomain = window.location.hostname;
const portalStatus = localStorage.getItem('portalStatus');
const specialTransfer = localStorage.getItem('specialTransfer');

let menuItems = {
    items: [dashboard, BankingServices, Wallet, MerchantEmployee, Batches, UtilityBillsAndEMI, CommonService, Pancard, RDServices, Reports]
};
if (specialTransfer === 'true') {
    const batchesIndex = menuItems.items.indexOf(Batches);
    if (batchesIndex !== -1) {
        menuItems.items.splice(batchesIndex + 1, 0, BankAccount);
    }
}
if (currentDomain === 'app.acemoney.in' || currentDomain === 'dev-app.acemoney.in' || currentDomain === 'localhost') {
    //ACEMONEY SUPPORT NUMBER SECTION NOT ACCESSIBLE FOR WHITELABEL
    menuItems.items.push(HelpLine);
} else if (portalStatus) {
    menuItems = { items: [dashboard, Ledger, RDServices, Reports] };
    menuItems.items.push(HelpLine);
} else {
    menuItems.items.push(CustomerSupport);
}
export default menuItems;
