/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons1120061340033',
            title: 'Dashboard',
            type: 'item',
            url: 'cspDashboard/dashboard',
            breadcrumbs: true
        },
        {
            id: 'icons2231438434222',
            title: 'Super Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3128413032107009',
                    title: 'Add',
                    type: 'item',
                    url: 'cspDashboard/addsuperdistributor',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532308220675',
                    title: 'List',
                    type: 'item',
                    url: '/cspDashboard/listsuperdistributor',
                    breadcrumbs: false
                }
               
            ]
        },
        {
            id: 'icons2238443227',
            title: 'Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53224118839001',
                    title: 'Add',
                    type: 'item',
                    url: '/cspDashboard/adddistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322211844129',
                    title: 'List',
                    type: 'item',
                    url: '/cspDashboard/listdistributor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223532564',
            title: 'Merchant',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53221510309',
                    title: 'List',
                    type: 'item',
                    url: '/cspDashboard/listmerchant',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'tabler-icons030133555',
            title: 'Daily Sales Monitoring',
            type: 'item',
            url: 'cspDashboard/dailySalesMonitoring',
            breadcrumbs: true
        },
        {
            id: 'icons22312233252',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121053112',
                    title: 'POS',
                    type: 'item',
                    url: 'cspDashboard/posTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons533225111',
                    title: 'QR',
                    type: 'item',
                    url: '/cspDashboard/qrTxnTrack',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223122355367',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31213401009',
                    title: 'DMT',
                    type: 'item',
                    url: 'cspDashboard/dmtTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53221143008',
                    title: 'BBPS',
                    type: 'item',
                    url: '/cspDashboard/bbpsTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53242131001',
                    title: 'RECHARGE',
                    type: 'item',
                    url: '/cspDashboard/rechargeTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53223114002',
                    title: 'MATM',
                    type: 'item',
                    url: '/cspDashboard/matmTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53342211003',
                    title: 'AEPS',
                    type: 'item',
                    url: '/cspDashboard/aepsTxnTrack',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Dashboard;