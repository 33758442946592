// assets
import { IconLayoutList, IconList, IconPlus } from '@tabler/icons';

// constant
const icons = {
    IconLayoutList,
    IconList,
    IconPlus
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Batches = {
    id: 'BatchwiseSettlement',
    title: 'Batchwise Settlement',
    type: 'group',
    children: [
        {
            id: 'batches',
            title: 'Batchwise Settlements',
            type: 'collapse',
            icon: icons.IconLayoutList,
            children: [
                {
                    id: 'batchList',
                    title: 'View Batches',
                    type: 'item',
                    url: 'batches/listbatch',
                    breadcrumbs: false,
                    icon: icons.IconList
                },
                {
                    id: 'createBatch',
                    title: 'Create Batches',
                    type: 'item',
                    url: 'batches/createBatch',
                    breadcrumbs: false,
                    icon: icons.IconPlus
                }
            ]
        }
    ]
};

export default Batches;
