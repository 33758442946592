import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Box } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { checkPermissionGet, rekycOtpSendApi, reKycOtpVerifyApi, reKycSubmitAPi } from 'utils/API/Merchant APIs/MerchantApiCalls';
import Spinner from '../LoadingSinner';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { LoadingButton } from '@mui/lab';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import PropTypes from 'prop-types';
const ReKYCDialog = ({ isSso }) => {
    const navigate = useNavigate();
    const [otpLoading, SetOtpLoading] = useState({ sending: false, verifying: false });
    const [loadingPanSubmit, setLoadingPanSubmit] = useState(false);
    const [otpToken, setOtpToken] = useState('');
    const [otp, setOtp] = useState('');
    const [panImageBase64, setPanImageBase64] = useState('');
    const [panImageName, setPanImageName] = useState('');
    const [aadhaarFrontImageBase64, setAadhaarFrontImageBase64] = useState('');
    const [aadhaarFrontImageName, setAadhaarFrontImageName] = useState('');
    const [aadhaarBackImageBase64, setAadhaarBackImageBase64] = useState('');
    const [aadhaarBackImageName, setAadhaarBackImageName] = useState('');
    const [status, setStatus] = useState(null);
    const [guidelines, setGuidelines] = useState(null);
    const [whitelabelStatus, setWhitelabelStatus] = useState(false);
    const [openChecker, setOpenChecker] = useState(null);

    useEffect(() => {
        getCheckPermissions();
        // eslint-disable-next-line
    }, []);
    //handlers>>>
    const onClose = () => {
        if (isSso) {
            return;
        }
        navigate('/merchant-home');
    };
    const handleImageUpload = (e, setImageBase64, setImageName) => {
        const file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png') && file.size <= 500 * 1024) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageBase64(reader.result); // Base64 string of the uploaded image
            };
            reader.readAsDataURL(file);
            setImageName(file.name);
        } else {
            globalNotifyError('Please upload a valid JPEG/PNG image not exceeding 500 KB.');
        }
    };
    const handlePanImageUpload = (e) => handleImageUpload(e, setPanImageBase64, setPanImageName);
    const handleAadhaarFrontImageUpload = (e) => handleImageUpload(e, setAadhaarFrontImageBase64, setAadhaarFrontImageName);
    const handleAadhaarBackImageUpload = (e) => handleImageUpload(e, setAadhaarBackImageBase64, setAadhaarBackImageName);
    //API calls>>>
    const sendOtpApiCall = async () => {
        SetOtpLoading({ sending: true, verifying: false });
        const response = await rekycOtpSendApi();
        if (response?.status === 200) {
            const phoneNo = response?.data?.phone;
            if (response?.data?.status) {
                globalNotifySuccess(`OTP has been sent to the contact number ${phoneNo} `);
                setGuidelines(null);
            }
        }
        SetOtpLoading({ sending: false, verifying: false });
    };
    const submitOtpApiCall = async () => {
        if (otp) {
            SetOtpLoading({ sending: false, verifying: true });
            const payload = { otp };
            const response = await reKycOtpVerifyApi(payload);
            if (response?.status === 200) {
                if (response?.data?.status) {
                    const token = response?.data?.token;
                    globalNotifySuccess(response?.data?.message);
                    setOtpToken(token);
                } else {
                    setOtpToken('');
                }
            } else {
                setOtpToken('');
            }
            SetOtpLoading({ sending: false, verifying: false });
        } else {
            globalNotifyError('Please enter a valid OTP to continue.');
        }
    };
    const submitPanUploadApiCall = async () => {
        if (panImageBase64) {
            setLoadingPanSubmit(true);
            let payload;
            if (isSso) {
                payload = {
                    rekyc_mandatory: true,
                    pan_image: panImageBase64,
                    aadhar_front: aadhaarFrontImageBase64,
                    aadhar_back: aadhaarBackImageBase64
                };
            } else {
                payload = {
                    token: otpToken,
                    pan_image: panImageBase64,
                    aadhar_front: aadhaarFrontImageBase64,
                    aadhar_back: aadhaarBackImageBase64
                };
            }

            const response = await reKycSubmitAPi(payload);
            if (response?.status === 200) {
                globalNotifySuccess('Details submitted successfully, Please wait for the approval.');
                getCheckPermissions();
            }
            setLoadingPanSubmit(false);
        } else {
            globalNotifyError('Please choose a pancard image before submission.');
        }
    };
    const getCheckPermissions = async () => {
        const permissionResponse = await checkPermissionGet();
        if (permissionResponse?.status === 200) {
            const reKyc = permissionResponse?.data?.rekyc;
            const rekycStatus = permissionResponse?.data?.rekyc_status;
            const guidelines = permissionResponse?.data?.guidelines;
            const whitelabel = permissionResponse?.data?.whitelabel;
            setOpenChecker(reKyc);
            setWhitelabelStatus(whitelabel);
            setStatus(rekycStatus);
            if (isSso) {
                setOtpToken('sso_rekyc');
                setGuidelines(null);
            } else {
                setGuidelines(guidelines);
            }
        }
    };
    const getDialogContent = () => {
        if (openChecker === 1) {
            switch (status) {
                case '':
                    return (
                        <>
                            {otpToken ? (
                                <>
                                    <Typography variant="body1" gutterBottom>
                                        {`${
                                            !isSso ? 'OTP Verified!' : ''
                                        } Please upload clear images of your PAN card and Aadhaar card (front and back).`}
                                    </Typography>

                                    {/* Image upload section with flex layout */}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                                        {/* PAN Card Upload */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Button variant="contained" component="label">
                                                Upload PAN Card
                                                <input type="file" accept="image/jpeg, image/png" hidden onChange={handlePanImageUpload} />
                                            </Button>

                                            {panImageBase64 ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box
                                                        component="img"
                                                        src={panImageBase64}
                                                        alt="PAN card preview"
                                                        sx={{ width: 80, height: 50, objectFit: 'cover', border: '1px solid #ddd' }}
                                                    />
                                                    <Typography variant="body2" color="green">
                                                        {panImageName}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary">
                                                    No PAN card image uploaded
                                                </Typography>
                                            )}
                                        </Box>

                                        {/* Aadhaar Front Upload */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Button variant="contained" component="label">
                                                Upload Aadhaar Front
                                                <input
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    hidden
                                                    onChange={handleAadhaarFrontImageUpload}
                                                />
                                            </Button>

                                            {aadhaarFrontImageBase64 ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box
                                                        component="img"
                                                        src={aadhaarFrontImageBase64}
                                                        alt="Aadhaar front preview"
                                                        sx={{ width: 80, height: 50, objectFit: 'cover', border: '1px solid #ddd' }}
                                                    />
                                                    <Typography variant="body2" color="green">
                                                        {aadhaarFrontImageName}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary">
                                                    No Aadhaar front image uploaded
                                                </Typography>
                                            )}
                                        </Box>

                                        {/* Aadhaar Back Upload */}
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                            <Button variant="contained" component="label">
                                                Upload Aadhaar Back
                                                <input
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    hidden
                                                    onChange={handleAadhaarBackImageUpload}
                                                />
                                            </Button>

                                            {aadhaarBackImageBase64 ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <Box
                                                        component="img"
                                                        src={aadhaarBackImageBase64}
                                                        alt="Aadhaar back preview"
                                                        sx={{ width: 80, height: 50, objectFit: 'cover', border: '1px solid #ddd' }}
                                                    />
                                                    <Typography variant="body2" color="green">
                                                        {aadhaarBackImageName}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography variant="body2" color="text.secondary">
                                                    No Aadhaar back image uploaded
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </>
                            ) : guidelines ? (
                                <>
                                    <DialogContent>
                                        <div dangerouslySetInnerHTML={{ __html: guidelines }} />
                                    </DialogContent>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1" gutterBottom>
                                        Please complete your Re-KYC by entering the OTP sent to your registered mobile number.
                                    </Typography>
                                    <TextField
                                        onChange={(event) => {
                                            if (event.target.value.length <= 6) {
                                                setOtp(event.target.value);
                                            }
                                        }}
                                        label="Enter OTP"
                                        variant="outlined"
                                        fullWidth
                                        value={otp}
                                        margin="normal"
                                    />
                                </>
                            )}
                            <Spinner loading={otpLoading.sending} />
                        </>
                    );
                case 'pending':
                    return <Typography variant="body1">Your Re-KYC request has been submitted and is pending for approval.</Typography>;
                case 'rejected':
                    return (
                        <>
                            <Typography variant="body1">
                                Your Re-KYC submission was <span style={{ color: 'red', fontWeight: 'bold' }}>REJECTED</span>. Please
                                Re-Submit your details.
                            </Typography>
                            <Button onClick={() => setStatus('')}>RE-SUBMIT NOW</Button>
                        </>
                    );
                default:
                    return null;
            }
        }
        return null;
    };

    const getDialogTitle = () => {
        if (openChecker === 1) {
            return 'Complete Re-KYC Process';
        }
        return null;
    };

    const getDialogActions = () => {
        if (openChecker === 1 && status === '') {
            return otpToken ? (
                <DialogActions>
                    <LoadingButton loading={loadingPanSubmit} onClick={submitPanUploadApiCall} color="primary">
                        Submit
                    </LoadingButton>
                </DialogActions>
            ) : guidelines ? (
                <DialogActions>
                    <LoadingButton endIcon={<DoubleArrowIcon />} loading={otpLoading?.sending} onClick={sendOtpApiCall} color="primary">
                        PROCEED
                    </LoadingButton>
                </DialogActions>
            ) : (
                <DialogActions>
                    <Tooltip title="Back to home">
                        <IconButton onClick={() => navigate('/merchant-home')} aria-label="home">
                            <HomeIcon />
                        </IconButton>
                    </Tooltip>
                    <LoadingButton disabled={otp.length !== 6} loading={otpLoading?.verifying} onClick={submitOtpApiCall} color="primary">
                        Submit OTP
                    </LoadingButton>
                </DialogActions>
            );
        }
        return (
            <Tooltip title="Back to home">
                <IconButton onClick={() => navigate('/merchant-home')} aria-label="home">
                    <HomeIcon />
                </IconButton>
            </Tooltip>
        );
    };

    if (!openChecker) return null; // Don't render dialog if rekyc is not 1

    return (
        <Dialog
            open={!whitelabelStatus && openChecker === 1}
            onClose={onClose}
            fullWidth
            maxWidth="md" // Adjust the width: 'sm', 'md', 'lg', 'xl', or 'false' for no max width
            sx={{ '& .MuiDialog-paper': { width: '700px', maxWidth: 'none' } }} // Further customize the width
        >
            <DialogTitle>
                <Typography variant="h4" fontWeight="bold" color="primary">
                    {getDialogTitle()}
                </Typography>
            </DialogTitle>
            <DialogContent>{getDialogContent()}</DialogContent>
            {getDialogActions()}
        </Dialog>
    );
};

export default ReKYCDialog;
ReKYCDialog.propTypes = {
    isSso: PropTypes.bool
};
