/* eslint-disable */

// assets
import {
    IconUserCircle,
    IconHierarchy,
    IconDashboard,
    IconList,
    IconCash,
    IconAdjustments,
    IconArrowDownRight,
    IconChartHistogram,
    IconDeviceAnalytics
} from '@tabler/icons';

// constant
const icons = {
    IconUserCircle,
    IconHierarchy,
    IconDashboard,
    IconList,
    IconCash,
    IconAdjustments,
    IconArrowDownRight,
    IconChartHistogram,
    IconDeviceAnalytics
};
const specialTransfer = localStorage.getItem('specialTransfer');
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const SdClassInfo = localStorage.getItem('SdClassInfo');
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist');
    if (SdClassInfo === '1') {
        if (isBCWalletTrue === 'true') {
            return 'Aggregator Dashboard';
        } else {
            return 'Convert as Aggregator';
        }
    } else {
        if (isBCWalletTrue === 'true') {
            return 'BC Dashboard';
        } else {
            return 'Convert as BC';
        }
    }
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

export const Dashboard = {
    id: 'menu-utilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'dashboard-home',
            title: 'Dashboard',
            type: 'item',
            url: 'super-distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'dashboard-bc-switch',
            title: getTitle('BC Dashboard', 'Convert As BC Agent'),
            type: 'item',
            url: 'super-distributor/bc-dashboard-redirection',
            breadcrumbs: true
        },
        // {
        //     id: 'tabler-icons2',
        //     title: 'Profile',
        //     type: 'item',
        //     url: 'super-distributor/profile',
        //     breadcrumbs: true
        // },
        {
            id: 'merchants-all',
            title: 'All Merchants',
            type: 'item',
            url: 'super-distributor/allmerchants',
            breadcrumbs: true
        },
        {
            id: 'bc-agents-all',
            title: 'All Bc Agents',
            type: 'item',
            url: 'super-distributor/allbc',
            breadcrumbs: true
        },
        {
            id: 'dbemployee',
            title: 'DB Employee List',
            type: 'item',
            url: 'super-distributor/employee-list',
            breadcrumbs: true
        },
        {
            id: 'onboarding-section',
            title: 'Onboarding',
            type: 'collapse',
            children: [
                // {
                //     id: 'tabler-icons312101',
                //     title: 'Onboarding',
                //     type: 'item',
                //     url: 'super-distributor/onboarding',
                //     breadcrumbs: true
                // },
                {
                    id: 'tabler-icons532211',
                    title: 'List',
                    type: 'item',
                    url: '/super-distributor/listallmerchants',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'employee-section',
            title: 'Employee',
            type: 'collapse',
            children: [
                {
                    id: 'createEmployee',
                    title: 'Create Employee',
                    type: 'item',
                    url: 'super-distributor/createEmployee',
                    breadcrumbs: true
                },
                {
                    id: 'listEmployee',
                    title: 'List Employee',
                    type: 'item',
                    url: '/super-distributor/listEmployee',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'batches-list',
            title: 'List Batches',
            type: 'item',
            url: 'super-distributor/listAllBatches',
            breadcrumbs: true
        },
        specialTransfer === 'true'
            ? {
                  id: 'documentationBBpsBiller',
                  title: 'Fund Management',
                  type: 'item',
                  url: 'super-distributor/fundtransfer',
                  breadcrumbs: true
              }
            : null,
        {
            id: 'soundbox-list',
            title: 'Soundbox',
            type: 'item',
            url: 'super-distributor/listsoundbox',
            breadcrumbs: true
        },
        {
            id: 'bc-settlement-list',
            title: 'BC Settlement List',
            type: 'item',
            url: 'super-distributor/listBcSettlements',
            breadcrumbs: true
        },
        {
            id: 'settlement-requests',
            title: 'Settlement Request',
            type: 'item',
            url: 'super-distributor/settlement-requests',
            breadcrumbs: true
        },
        // Temporary hide as per the requirement from product team
        // {
        //     id: 'tabler-icons0033',
        //     title: 'Counts',
        //     type: 'item',
        //     url: 'super-distributor/countlist',
        //     breadcrumbs: true
        // },
        {
            id: 'distributors-list',
            title: getTitleLabel2(),
            type: 'item',
            url: 'super-distributor/distributors',
            breadcrumbs: true
        },
        {
            id: 'sub-distributors-list',
            title: getTitleLabel3(),
            type: 'item',
            url: 'super-distributor/list-subdistributor',
            breadcrumbs: true
        },
        {
            id: 'wallet-requests',
            title: 'Wallet Request',
            type: 'item',
            url: 'super-distributor/walletrequest',
            breadcrumbs: true
        },
        {
            id: 'wallet-approve',
            title: 'Approve Wallet',
            type: 'item',
            url: 'super-distributor/approvewallet',
            breadcrumbs: true
        },
        {
            id: 'bank-details',
            title: 'Bank Details',
            type: 'item',
            url: 'super-distributor/bankdetails',
            breadcrumbs: true
        },
        {
            id: 'incentive-report',
            title: 'Incentive Eligible Report',
            type: 'item',
            url: 'super-distributor/incentivereport'
        },
        {
            id: 'merchant-transactions',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31210',
                    title: 'POS',
                    type: 'item',
                    url: '/super-distributor/pos-transactions',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532211',
                    title: 'QR',
                    type: 'item',
                    url: '/super-distributor/qr-transactions',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'bc-transactions',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312111',
                    title: 'DMT',
                    type: 'item',
                    url: '/super-distributor/dmt-transactions',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322111',
                    title: 'BBPS',
                    type: 'item',
                    url: '/super-distributor/bbps-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322112',
                    title: 'Recharge',
                    type: 'item',
                    url: '/super-distributor/recharge-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322113',
                    title: 'MATM',
                    type: 'item',
                    url: '/super-distributor/matm-transactions',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322114',
                    title: 'AEPS',
                    type: 'item',
                    url: '/super-distributor/aeps-transactions',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transaction-history',
            title: 'Transaction History',
            type: 'item',
            url: 'super-distributor/transaction-history',
            breadcrumbs: true
        },
        {
            id: 'my-transactions',
            title: 'My Transaction',
            type: 'item',
            url: 'super-distributor/my-transactions',
            breadcrumbs: true
        }
        //     ]
        // }
    ].filter(Boolean)
};

export const CustomSideMenu1 = {
    id: 'customMenu1',
    // title: 'Admin menu',
    type: 'group',
    children: [
        {
            id: 'custom-admin-dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: icons.IconDashboard,
            url: 'super-distributor/dashboard',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-branches',
            title: 'All Branches',
            type: 'item',
            icon: icons.IconHierarchy,
            url: 'super-distributor/admin-branches',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-transaction-history',
            title: 'Transaction History',
            type: 'item',
            icon: icons.IconList,
            url: 'super-distributor/admin-transaction-history',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-my-transactions',
            title: 'Wallet Transactions',
            type: 'item',
            icon: icons.IconCash,
            url: 'super-distributor/admin-my-transactions',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-branch-settlements',
            title: 'Branch Settlement List',
            type: 'item',
            icon: icons.IconAdjustments,
            url: 'super-distributor/branch-settlements',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-branch-requests',
            title: 'Branch Request List',
            type: 'item',
            icon: icons.IconArrowDownRight,
            url: 'super-distributor/branch-requests',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-branch-analysis',
            title: 'Monthly Analysis',
            type: 'item',
            icon: icons.IconChartHistogram,
            url: 'super-distributor/monthly-analysis',
            breadcrumbs: true
        },
        {
            id: 'custom-admin-analytics',
            title: 'Analytics Dashboard',
            type: 'item',
            icon: icons.IconDeviceAnalytics,
            url: 'super-distributor/analytics-iframe',
            breadcrumbs: true
        }
    ]
};
