/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons112333433',
            title: 'Dashboard',
            type: 'item',
            url: 'stateHeadDashboard/dashboard',
            breadcrumbs: true
        },
        {
            id: 'icons223144222',
            title: 'Super Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3124410107009',
                    title: 'Add',
                    type: 'item',
                    url: 'stateHeadDashboard/addsuperdistributor',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53221410300675',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listsuperdistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53221104909115',
                    title: 'Mapping',
                    type: 'item',
                    url: '/stateHeadDashboard/superdistributormapping',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22314227',
            title: 'Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons532241189001',
                    title: 'Add',
                    type: 'item',
                    url: '/stateHeadDashboard/adddistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322118144129',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listdistributor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22312001224',
            title: 'Middle Manager ',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3110021015',
                    title: 'Add',
                    type: 'item',
                    url: 'stateHeadDashboard/addmiddlemanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5321002116',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listmiddlemanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22300133223',
            title: 'Manager',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3102331013',
                    title: 'Add',
                    type: 'item',
                    url: 'stateHeadDashboard/addmanager',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322110334',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listmanager',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231203332',
            title: 'Csp/Sanchalak',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121001220',
                    title: 'Add',
                    type: 'item',
                    url: 'stateHeadDashboard/addcsp',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322011222',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listcsp',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22312325364',
            title: 'Merchant',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons53221120309',
                    title: 'List',
                    type: 'item',
                    url: '/stateHeadDashboard/listmerchant',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'tabler-icons030133255',
            title: 'Daily Sales Monitoring',
            type: 'item',
            url: 'stateHeadDashboard/dailySalesMonitoring',
            breadcrumbs: true
        },
        {
            id: 'icons22312233222',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3122103112',
                    title: 'POS',
                    type: 'item',
                    url: 'stateHeadDashboard/posTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons532322111',
                    title: 'QR',
                    type: 'item',
                    url: '/stateHeadDashboard/qrTxnTrack',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons223122325367',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons31212301009',
                    title: 'DMT',
                    type: 'item',
                    url: 'stateHeadDashboard/dmtTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53222113008',
                    title: 'BBPS',
                    type: 'item',
                    url: '/stateHeadDashboard/bbpsTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53222131001',
                    title: 'RECHARGE',
                    type: 'item',
                    url: '/stateHeadDashboard/rechargeTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53222311002',
                    title: 'MATM',
                    type: 'item',
                    url: '/stateHeadDashboard/matmTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons53322211003',
                    title: 'AEPS',
                    type: 'item',
                    url: '/stateHeadDashboard/aepsTxnTrack',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Dashboard;
