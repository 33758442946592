// assets
import { IconListCheck, IconListDetails, IconList, IconTable } from '@tabler/icons';

// constant
const icons = {
    IconListCheck,
    IconListDetails,
    IconList,
    IconTable
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Ledger = {
    id: 'legderMenu',
    type: 'group',
    title: '',
    children: [
        {
            id: 'ledgermenuItems',
            title: 'Ledgers',
            type: 'collapse',
            icon: icons.IconTable,
            children: [
                {
                    id: 'ledgermenu1',
                    title: 'Ledger 1',
                    type: 'item',
                    url: '/merchants/all-transactions',
                    icon: icons.IconListDetails,
                    breadcrumbs: false
                },
                {
                    id: 'ledgermenu2',
                    title: 'Ledger 2',
                    type: 'item',
                    url: '/merchants/transactions',
                    icon: icons.IconListCheck,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Ledger;
