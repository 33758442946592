import { Alert } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { ListAllBatchesApi, UpdatestatusBatchesApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import AccordionComponent from 'views/BatchwiseSettlement/AccordionComponent';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import Spinner from 'views/Components/LoadingSinner';
import { globalNotifySuccess } from 'views/utilities/AlertToast';
const breadcrumbRoutes = [{ label: 'List Batches', path: '' }];

const ListAllBatches = () => {
    const [batchData, setBatchData] = useState([]);
    const [loading, setLoading] = useState(false);
    //api calls>>>
    const getAllBatchList = async () => {
        setLoading(true);
        const response = await ListAllBatchesApi();
        if (response?.status === 200) {
            const data = response?.data?.data;
            setBatchData(data);
        }
        setLoading(false);
    };
    //api call for approve and reject>>
    const handleApprove = async (batchName, data) => {
        setLoading(true);
        const payload = { batch: data?.batch_id, status: 'success' };
        const response = await UpdatestatusBatchesApi(payload);
        if (response?.status === 200) {
            globalNotifySuccess('Batch has been approved !');
            getAllBatchList();
        }
        setLoading(false);
    };

    const handleReject = async (batchName, data) => {
        setLoading(true);
        const payload = { batch: data?.batch_id, status: 'failed' };
        const response = await UpdatestatusBatchesApi(payload);
        if (response?.status === 200) {
            globalNotifySuccess('Batch has been rejected !');
            getAllBatchList();
        }
        setLoading(false);
    };

    useEffect(() => {
        getAllBatchList();
    }, []);
    return (
        <>
            <BreadcrumbComponent routes={breadcrumbRoutes} />
            {batchData.length > 0 ? (
                <AccordionComponent batchData={batchData} isList={true} isAdmin={true} onApprove={handleApprove} onReject={handleReject} />
            ) : (
                <Alert variant="filled" severity="info">
                    Oops! Looks like there are no transactions.
                </Alert>
            )}
            <Spinner loading={loading} />
        </>
    );
};
export default ListAllBatches;
