/* eslint-disable */

import React from 'react';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const useAuth = () => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('subrole');
    const type = localStorage.getItem('type');

    if (type === 'relationmanager' && role === 'middle_manager') {
        if (token) {
            const decoded = jwt_decode(token);
            // Check if the token has expired
            if (decoded.exp < Date.now() / 1000) {
                // Token has expired
                return 1;
            } else {
                // Token is still valid
                const user = token;
                return user && token;
            }
        } else {
            return 1;
        }
    } else {
        return 2;
    }
};

const ProtectedRouteMiddlemanager= ({ element }) => {
    const isAuth = useAuth();
    if (isAuth) {
        if (isAuth === 1) {
            return <Navigate to="/" replace />; // Redirect to home if token is invalid or expired
        } else if (isAuth === 2) {
            return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page if role is incorrect
        } else {
            return element; // Render the protected element if authentication and role checks pass
        }
    } else {
        return <Navigate to="/" replace />; // Redirect to home if no valid user
    }
};

export default ProtectedRouteMiddlemanager;
