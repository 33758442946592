import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteManager from 'routes/protectedRouteManager';

const DashboardManager = Loadable(lazy(() => import('views/managerDashboard/dashboard')));
const SuperDistributorForm = Loadable(lazy(() => import('views/managerDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/managerDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/managerDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/managerDashboard/distributor/add')));
const CspForm = Loadable(lazy(() => import('views/managerDashboard/csp/add')));
const CspList = Loadable(lazy(() => import('views/managerDashboard/csp/list')));
const RelationManagerProfile = Loadable(lazy(() => import('views/managerDashboard/profile')));
const ViewCsp = Loadable(lazy(() => import('views/managerDashboard/viewcsp')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/managerDashboard/viewsuperdistributor')));
const ViewDistributor = Loadable(lazy(() => import('views/managerDashboard/viewdistributor')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/managerDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/managerDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/managerDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/managerDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/managerDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/managerDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/managerDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/managerDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/managerDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/managerDashboard/viewMerchant')));

export const managerRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteManager element={<DashboardManager />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteManager element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteManager element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteManager element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteManager element={<DistributorForm />} />
        },
        {
            path: 'addcsp',
            element: <ProtectedRouteManager element={<CspForm />} />
        },
        {
            path: 'listcsp',
            element: <ProtectedRouteManager element={<CspList />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteManager element={<RelationManagerProfile />} />
        },
        {
            path: 'ViewCsp',
            element: <ProtectedRouteManager element={<ViewCsp />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteManager element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteManager element={<ViewDistributor />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteManager element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteManager element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteManager element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteManager element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteManager element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteManager element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteManager element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteManager element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteManager element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteManager element={<ViewMerchant />} />
        }
    ]
};
