import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';

const AnalyticsIframe = ({ title = 'Analytics Dashboard', height = '600px', width = '100%' }) => {
    const src = localStorage.getItem('iframeAnalyticsLink');
    const handleRefresh = () => {
        const iframe = document.querySelector('iframe');
        if (iframe) {
            iframe.src = iframe.src;
        }
    };

    const handleOpenInNewTab = () => {
        window.open(src, '_blank');
    };

    return (
        <Paper
            elevation={3}
            sx={{
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    backgroundColor: 'background.default'
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 600,
                        color: 'text.primary'
                    }}
                >
                    {title}
                </Typography>
                <Box>
                    <Tooltip title="Refresh Dashboard">
                        <IconButton onClick={handleRefresh} color="primary" size="small" sx={{ mr: 1 }}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Open in New Tab">
                        <IconButton onClick={handleOpenInNewTab} color="secondary" size="small">
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box
                sx={{
                    width: width,
                    height: height,
                    position: 'relative'
                }}
            >
                <iframe
                    src={src}
                    title={title}
                    width="100%"
                    height="100%"
                    style={{
                        border: 'none',
                        transition: 'opacity 0.3s ease'
                    }}
                    allowFullScreen
                    loading="lazy"
                />
            </Box>
        </Paper>
    );
};

export default AnalyticsIframe;
AnalyticsIframe.propTypes = {
    title: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string
};
