import { Box, TextField, Grid, Paper, Autocomplete, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import { storeEditMerchantsApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import { globalNotifySuccess } from 'views/utilities/AlertToast';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
const breadCrumbRoutes = [{ label: 'Edit Branch Details', path: '' }];

const EditBranch = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const profile = location.state?.profile || {};
    const stateList = location.state?.stateList || [];
    const districtList = useMemo(() => location.state?.districtList || [], [location.state?.districtList]);

    // **State Selection**
    const [selectedState, setSelectedState] = useState(stateList.find((s) => s.state_code == profile.state) || null);

    // **District Selection**
    const [filteredDistricts, setFilteredDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(districtList.find((d) => d.id == profile.district) || null);
    const [dob, setDob] = useState(profile.dob ? dayjs(profile.dob) : null);
    const [submitLoading, setSubmitLoading] = useState(false);
    // Formik validation schema
    const validationSchema = Yup.object({
        agname: Yup.string().required('First Name is required'),
        mname: Yup.string(),
        lname: Yup.string(),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        phone: Yup.string()
            .matches(/^[6-9]\d{9}$/, 'Invalid phone number')
            .required('Phone is required'),
        gender: Yup.string().required('Gender is required'),
        state_name: Yup.string().required('State is required'),
        district_id: Yup.string().required('District is required'),
        address: Yup.string().required('Address is required'),
        pin: Yup.string()
            .matches(/^\d{6}$/, 'Invalid pincode')
            .required('Pincode is required'),
        post: Yup.string().required('post is required'),
        balance: Yup.number().typeError('Balance must be a number').required('Balance is required'),
        account_name: Yup.string().required('Account Name is required'),
        account_number: Yup.string()
            .matches(/^\d{9,18}$/, 'Invalid Account Number')
            .required('Account Number is required'),
        bank_name: Yup.string().required('Bank Name is required'),
        branch: Yup.string().required('Branch is required'),
        ifsc: Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code')
            .required('IFSC Code is required'),
        kyc_type: Yup.string().required('KYC Type is required'),
        // 🔹 Aadhaar Number Validation (12-digit numeric)
        aadhaar: Yup.string()
            .matches(/^\d{12}$/, 'Invalid Aadhaar Number')
            .required('Aadhaar Number is required'),

        // 🔹 PAN Card Validation (Format: ABCDE1234F)
        pancard: Yup.string()
            .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, 'Invalid PAN Card Number')
            .required('PAN Card is required')
    });

    // Initialize Formik
    const formik = useFormik({
        initialValues: {
            agname: profile.agname || '',
            mname: profile.mname || '',
            lname: profile.lname || '',
            email: profile.email || '',
            phone: profile.phone || '',
            gender: profile.gender || '',
            state_name: profile.state || '',
            district_id: selectedDistrict?.id.toString() || '',
            address: profile.address || '',
            pin: profile.pin || '',
            post: profile.post || '',
            aadhaar: (profile.adhaarno || '').replace(/\s+/g, ''),
            pancard: profile.pancard || '',
            balance: profile.balance || '',
            account_name: profile.accountname || '',
            account_number: profile.accountno || '',
            bank_name: profile.bank_name || '',
            branch: profile.bank_branch || '',
            ifsc: profile.bank_ifsc || '',
            kyc_type: profile.kyctype || '',
            dob: profile.dob || ''
        },
        enableReinitialize: false, // Ensures form updates when profile data changes
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSave(values);
        }
    });
    //handlers>>>
    const transformUserData = (userData) => {
        return {
            firstname: userData.agname || '',
            middlename: userData.mname || '',
            lastname: userData.lname || '',
            phoneno: userData.phone || '',
            dob: userData.phone || '',
            gender: userData.gender || '',
            email: userData.email || '',
            pincode: userData.pin || '',
            address: userData.address || '',
            landmark: userData.address || '',
            district: userData.district_id || '',
            state: userData.state_name || '',
            pancard: userData.pancard || '',
            aadharno: userData.aadhaar || '',
            post: userData.post || '',
            accountno: userData.account_number || '',
            accountname: userData.account_name || '',
            branch: userData.branch || '',
            bankname: userData.bank_name || '',
            ifsc: userData.ifsc || ''
        };
    };
    //api handlers>>
    const onSave = async (values) => {
        setSubmitLoading(true);
        const transformedData = transformUserData(values);
        const payload = { ...transformedData, id: profile?.id };
        const response = await storeEditMerchantsApi(payload);
        if (response?.status === 200) {
            globalNotifySuccess('Changes saved successfully');
            navigate('/super-distributor/branch-view', { state: { params: { id: profile?.id } } });
        }
        setSubmitLoading(false);
    };

    useEffect(() => {
        if (selectedState) {
            setFilteredDistricts(districtList.filter((d) => d.state_code === selectedState.state_code));
        } else {
            setFilteredDistricts([]);
        }
    }, [selectedState, districtList]);
    // useEffect(() => {
    //     console.log('formik.errors', formik.errors);
    // }, [formik.errors]);
    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        padding: 3,
                        borderRadius: 2,
                        backgroundColor: '#fff'
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Name Fields */}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="First Name"
                                    name="agname"
                                    {...formik.getFieldProps('agname')}
                                    error={formik.touched.agname && Boolean(formik.errors.agname)}
                                    helperText={formik.touched.agname && formik.errors.agname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Middle Name" name="mname" {...formik.getFieldProps('mname')} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField fullWidth label="Last Name" name="lname" {...formik.getFieldProps('lname')} />
                            </Grid>
                            {/* Email and Phone */}
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    {...formik.getFieldProps('email')}
                                    disabled={true}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    disabled={true}
                                    type="tel"
                                    {...formik.getFieldProps('phone')}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth error={formik.touched.gender && Boolean(formik.errors.gender)}>
                                    <InputLabel>Gender</InputLabel>
                                    <Select
                                        name="gender"
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    >
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                    </Select>
                                    <FormHelperText>{formik.touched.gender && formik.errors.gender}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Date of Birth *"
                                            value={dob}
                                            format="DD/MM/YYYY"
                                            onChange={(newValue) => {
                                                setDob(newValue);
                                                formik.setFieldValue('dob', newValue ? newValue.format('YYYY-MM-DD') : '');
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            {/* State Selection */}
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    options={stateList}
                                    getOptionLabel={(option) => option.state}
                                    value={selectedState}
                                    onChange={(event, newValue) => {
                                        setSelectedState(newValue);
                                        setSelectedDistrict(null);
                                        formik.setFieldValue('state_id', newValue ? newValue.id : '');
                                        formik.setFieldValue('district_id', ''); // Reset district
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="State"
                                            error={formik.touched.state_id && Boolean(formik.errors.state_id)}
                                            helperText={formik.touched.state_id && formik.errors.state_id}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* District Selection */}
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    options={filteredDistricts}
                                    getOptionLabel={(option) => option.dist}
                                    value={selectedDistrict}
                                    onChange={(event, newValue) => {
                                        setSelectedDistrict(newValue);
                                        formik.setFieldValue('district_id', newValue ? newValue.id : '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="District"
                                            error={formik.touched.district_id && Boolean(formik.errors.district_id)}
                                            helperText={formik.touched.district_id && formik.errors.district_id}
                                        />
                                    )}
                                    disabled={!selectedState}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    name="address"
                                    multiline
                                    rows={2}
                                    {...formik.getFieldProps('address')}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                    helperText={formik.touched.address && formik.errors.address}
                                />
                            </Grid>
                            {/* Pincode, Landmark, Balance */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Pincode"
                                    name="pin"
                                    {...formik.getFieldProps('pin')}
                                    error={formik.touched.pin && Boolean(formik.errors.pin)}
                                    helperText={formik.touched.pin && formik.errors.pin}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Post"
                                    name="post"
                                    {...formik.getFieldProps('post')}
                                    error={formik.touched.post && Boolean(formik.errors.post)}
                                    helperText={formik.touched.post && formik.errors.post}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Aadhaar Number"
                                    name="aadhaar"
                                    inputProps={{
                                        maxLength: 12,
                                        pattern: '[0-9]*',
                                        inputMode: 'numeric'
                                    }}
                                    onKeyDown={(e) => {
                                        // Allow only digits, navigation keys, and delete/backspace
                                        if (
                                            !/[0-9]/.test(e.key) &&
                                            !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    {...formik.getFieldProps('aadhaar')}
                                    error={formik.touched.aadhaar && Boolean(formik.errors.aadhaar)}
                                    helperText={formik.touched.aadhaar && formik.errors.aadhaar}
                                />
                            </Grid>{' '}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Pancard"
                                    name="pancard"
                                    {...formik.getFieldProps('pancard')}
                                    onChange={(e) => {
                                        const upperCaseValue = e.target.value.toUpperCase();
                                        formik.setFieldValue('pancard', upperCaseValue);
                                    }}
                                    inputProps={{
                                        style: { textTransform: 'uppercase' },
                                        maxLength: 10
                                    }}
                                    onKeyDown={(e) => {
                                        // Allow only alphanumeric, navigation keys, and delete/backspace
                                        if (
                                            !/[a-zA-Z0-9]/.test(e.key) &&
                                            !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    error={formik.touched.pancard && Boolean(formik.errors.pancard)}
                                    helperText={formik.touched.pancard && formik.errors.pancard}
                                />
                            </Grid>
                            {/* Banking Details */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Account Name"
                                    name="account_name"
                                    {...formik.getFieldProps('account_name')}
                                    error={formik.touched.account_name && Boolean(formik.errors.account_name)}
                                    helperText={formik.touched.account_name && formik.errors.account_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Account Number"
                                    name="account_number"
                                    {...formik.getFieldProps('account_number')}
                                    error={formik.touched.account_number && Boolean(formik.errors.account_number)}
                                    helperText={formik.touched.account_number && formik.errors.account_number}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Bank Name"
                                    name="bank_name"
                                    {...formik.getFieldProps('bank_name')}
                                    error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                                    helperText={formik.touched.bank_name && formik.errors.bank_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Branch"
                                    name="branch"
                                    {...formik.getFieldProps('branch')}
                                    error={formik.touched.branch && Boolean(formik.errors.branch)}
                                    helperText={formik.touched.branch && formik.errors.branch}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="IFSC Code"
                                    name="ifsc"
                                    {...formik.getFieldProps('ifsc')}
                                    inputProps={{
                                        style: { textTransform: 'uppercase' },
                                        maxLength: 11
                                    }}
                                    onChange={(e) => {
                                        const upperCaseValue = e.target.value.toUpperCase();
                                        formik.setFieldValue('ifsc', upperCaseValue);
                                    }}
                                    error={formik.touched.ifsc && Boolean(formik.errors.ifsc)}
                                    helperText={formik.touched.ifsc && formik.errors.ifsc}
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="KYC Type" name="kyc_type" {...formik.getFieldProps('kyc_type')} />
                            </Grid> */}
                            {/* Submit Button */}
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <LoadingButton loading={submitLoading} type="submit" variant="contained" color="primary">
                                    Save Changes
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Box>
        </>
    );
};
export default EditBranch;
