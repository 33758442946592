import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteZonehead from 'routes/protectedRouteZonehead';

const DashboardZonehead = Loadable(lazy(() => import('views/zoneHeadDashboard/dashboard')));
const SuperDistributorForm = Loadable(lazy(() => import('views/zoneHeadDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/zoneHeadDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/zoneHeadDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/zoneHeadDashboard/distributor/add')));
const StateHeadForm = Loadable(lazy(() => import('views/zoneHeadDashboard/statehead/add')));
const StateHeadList = Loadable(lazy(() => import('views/zoneHeadDashboard/statehead/list')));
const MiddleManager = Loadable(lazy(() => import('views/zoneHeadDashboard/middlemanager/add')));
const MiddleManagerList = Loadable(lazy(() => import('views/zoneHeadDashboard/middlemanager/list')));
const ManagerForm = Loadable(lazy(() => import('views/zoneHeadDashboard/manager/add')));
const ManagerList = Loadable(lazy(() => import('views/zoneHeadDashboard/manager/list')));
const CspForm = Loadable(lazy(() => import('views/zoneHeadDashboard/csp/add')));
const CspList = Loadable(lazy(() => import('views/zoneHeadDashboard/csp/list')));
const ViewStateHead = Loadable(lazy(() => import('views/zoneHeadDashboard/viewstatehead')));
const ViewMiddleManager = Loadable(lazy(() => import('views/zoneHeadDashboard/viewmiddlemanager')));
const ViewManager = Loadable(lazy(() => import('views/zoneHeadDashboard/viewmanager')));
const ViewCsp = Loadable(lazy(() => import('views/zoneHeadDashboard/viewcsp')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/zoneHeadDashboard/viewsuperditributor')));
const ViewDistributor = Loadable(lazy(() => import('views/zoneHeadDashboard/viewdistributor')));
const SuperDistributorMapping = Loadable(lazy(() => import('views/zoneHeadDashboard/superdistributor/mapping')));
const RelationManagerProfile = Loadable(lazy(() => import('views/zoneHeadDashboard/profile')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/zoneHeadDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/zoneHeadDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/zoneHeadDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/zoneHeadDashboard/viewMerchant')));

export const zoneheadRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteZonehead element={<DashboardZonehead />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteZonehead element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteZonehead element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteZonehead element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteZonehead element={<DistributorForm />} />
        },
        {
            path: 'addstateheads',
            element: <ProtectedRouteZonehead element={<StateHeadForm />} />
        },
        {
            path: 'liststateheads',
            element: <ProtectedRouteZonehead element={<StateHeadList />} />
        },
        {
            path: 'addmiddlemanager',
            element: <ProtectedRouteZonehead element={<MiddleManager />} />
        },
        {
            path: 'listmiddlemanager',
            element: <ProtectedRouteZonehead element={<MiddleManagerList />} />
        },
        {
            path: 'addmanager',
            element: <ProtectedRouteZonehead element={<ManagerForm />} />
        },
        {
            path: 'listmanager',
            element: <ProtectedRouteZonehead element={<ManagerList />} />
        },
        {
            path: 'addcsp',
            element: <ProtectedRouteZonehead element={<CspForm />} />
        },
        {
            path: 'listcsp',
            element: <ProtectedRouteZonehead element={<CspList />} />
        },
        {
            path: 'ViewStateHead',
            element: <ProtectedRouteZonehead element={<ViewStateHead />} />
        },
        {
            path: 'ViewMiddleManager',
            element: <ProtectedRouteZonehead element={<ViewMiddleManager />} />
        },
        {
            path: 'ViewManager',
            element: <ProtectedRouteZonehead element={<ViewManager />} />
        },
        {
            path: 'ViewCsp',
            element: <ProtectedRouteZonehead element={<ViewCsp />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteZonehead element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteZonehead element={<ViewDistributor />} />
        },
        {
            path: 'superdistributormapping',
            element: <ProtectedRouteZonehead element={<SuperDistributorMapping />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteZonehead element={<RelationManagerProfile />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteZonehead element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteZonehead element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteZonehead element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteZonehead element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteZonehead element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteZonehead element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteZonehead element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteZonehead element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteZonehead element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteZonehead element={<ViewMerchant />} />
        }
    ]
};
