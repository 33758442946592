import { LoadingButton } from '@mui/lab';
import { Box, TextField, FormControl, InputLabel, MenuItem, Select, Card, CardContent, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { handleExcelDownloadGlobal } from 'utils/exports/excelDownload';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import { listBranchRequestsAdminApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
const breadCrumbRoutes = [{ label: 'Branch Request List', path: '' }];
const styles = {
    cardContainer: {
        backgroundColor: '#fff',
        display: 'flex',
        gap: '24px',
        padding: '24px',
        flexWrap: 'wrap',
        '@media (max-width: 768px)': {
            flexDirection: 'column'
        }
    },
    card: {
        flex: 1,
        minWidth: '250px',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 24px rgba(0,0,0,0.15)'
        }
    },
    cardContent: {
        padding: '24px !important',
        position: 'relative',
        zIndex: 1
    },
    iconContainer: {
        position: 'absolute',
        top: '20px',
        right: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2
    },
    count: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginTop: '16px'
    },
    label: {
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        fontWeight: 500
    },
    success: {
        background: 'linear-gradient(135deg, #e8f5e9 0%, #c8e6c9 100%)',
        '& .MuiSvgIcon-root': {
            color: '#2e7d32'
        },
        '& .MuiTypography-root': {
            color: '#1b5e20'
        }
    },
    pending: {
        background: 'linear-gradient(135deg, #fff8e1 0%, #ffe0b2 100%)',
        '& .MuiSvgIcon-root': {
            color: '#f57c00'
        },
        '& .MuiTypography-root': {
            color: '#e65100'
        }
    },
    failure: {
        background: 'linear-gradient(135deg, #ffebee 0%, #ffcdd2 100%)',
        '& .MuiSvgIcon-root': {
            color: '#c62828'
        },
        '& .MuiTypography-root': {
            color: '#b71c1c'
        }
    }
};

const BranchRequestList = () => {
    const today = dayjs().format('YYYY/MM/DD');
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [rowCount, setRowCount] = useState(0);
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [statusFilter, setStatusFilter] = useState('');
    const [statusData, setStatusData] = useState([
        {
            status: 'success',
            label: 'Successful Requests',
            count: '',
            icon: <CheckCircleIcon />
        },
        {
            status: 'pending',
            label: 'Pending Requests',
            count: '',
            icon: <PendingIcon />
        },
        {
            status: 'failure',
            label: 'Failed Requests',
            count: '',
            icon: <ErrorIcon />
        }
    ]);

    //handler functions>>>
    const updateCount = (status, newCount) => {
        setStatusData((prevData) => prevData.map((item) => (item.status === status ? { ...item, count: newCount } : item)));
    };
    const handleFromDateChange = (newValue) => {
        setFromDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };

    const handleToDateChange = (newValue) => {
        setToDate(newValue ? dayjs(newValue).format('YYYY/MM/DD') : null);
    };
    const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
    };
    // Handle page size change
    const handlePageSizeChange = (params) => {
        setPage(params.page + 1);
        setPageSize(params?.pageSize);
    };
    const handleExcelClick = () => {
        const pagination = false;
        getBranchRequestList(pagination);
    };
    const handleSearchClick = () => {
        const pagination = true;
        getBranchRequestList(pagination);
    };
    //api call functions>>>
    const getBranchRequestList = async (pagination) => {
        let payload = {};
        if (pagination) {
            setLoading(true);
            payload = { fromdate: fromDate, todate: toDate, start: page, length: pageSize, status: statusFilter };
        } else {
            setLoadingExcel(true);
            payload = { fromdate: fromDate, todate: toDate, start: '', length: '', status: statusFilter };
        }
        const response = await listBranchRequestsAdminApi(payload);
        if (response?.status === 200) {
            const dataList = response?.data?.data;
            const count = response?.data?.datacount;
            const totalSuccess = response?.data?.total_success;
            const totalPending = response?.data?.total_pending;
            const totalFailure = response?.data?.total_failure;
            updateCount('success', totalSuccess);
            updateCount('pending', totalPending);
            updateCount('failure', totalFailure);
            if (pagination) {
                setRequests(dataList);
                setRowCount(count);
            } else {
                handleExcelDownloadGlobal(columns, dataList, 'BRANCH_REQUESTS');
            }
        }
        setLoading(false);
        setLoadingExcel(false);
    };

    useEffect(() => {
        const pagination = true;
        getBranchRequestList(pagination);
        // eslint-disable-next-line
    }, [page, pageSize]);
    const columns = [
        {
            field: 'index',
            headerName: 'No.',
            width: 60,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 // Calculate row index (starting from 1)
        },
        {
            field: 'created_at',
            headerName: 'Date',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                const date = new Date(params.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (1 + date.getMonth()).toString().padStart(2, '0');
                const year = date.getFullYear();
                let hour = date.getHours();
                const minute = date.getMinutes().toString().padStart(2, '0');
                const meridiem = hour >= 12 ? 'PM' : 'AM';
                hour = hour % 12 || 12; // Convert hour to 12-hour format
                return `${day}-${month}-${year} ${hour}:${minute} ${meridiem}`;
            }
        },
        {
            field: 'agent_code',
            headerName: 'Agent ID',
            width: 250,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'name',
            headerName: 'Branch Name',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'branch',
            headerName: 'Requested Branch',
            width: 220,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'mobile',
            headerName: 'Phone No.',
            width: 190,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'refId',
            headerName: 'Reference ID',
            width: 180,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center' // Center-align header
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            align: 'center', // Center-align content
            headerAlign: 'center', // Center-align header
            renderCell: (params) => {
                if (params.value === 'failure') {
                    return <span style={{ color: 'red' }}>{params.value.toUpperCase()}</span>;
                } else if (params.value === 'success') {
                    return <span style={{ color: 'green' }}>{params.value.toUpperCase()}</span>;
                } else if (params.value === 'pending') {
                    return <span style={{ color: 'orange' }}>{params.value.toUpperCase()}</span>;
                }
            }
        }
    ];
    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        boxShadow: 2,
                        marginBottom: 2,
                        padding: '20px 10px',
                        '@media screen and (min-width: 600px)': {
                            padding: 2
                        }
                    }}
                >
                    <Box sx={styles.cardContainer}>
                        {statusData?.map(({ status, label, count, icon }) => (
                            <Card
                                key={status}
                                sx={{
                                    ...styles.card,
                                    ...styles[status]
                                }}
                            >
                                <Box sx={styles.iconContainer}>{icon}</Box>
                                <CardContent sx={styles.cardContent}>
                                    <Typography sx={styles.label}>{label}</Typography>
                                    <Typography sx={styles.count}>{count}</Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <DatePicker
                            label="From Date"
                            value={fromDate ? dayjs(fromDate, 'YYYY/MM/DD') : null}
                            onChange={handleFromDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate ? dayjs(toDate, 'YYYY/MM/DD') : null}
                            onChange={handleToDateChange}
                            format="YYYY/MM/DD"
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        <FormControl sx={{ width: 200 }}>
                            <InputLabel>Status</InputLabel>
                            <Select value={statusFilter} onChange={handleStatusChange} displayEmpty>
                                <MenuItem value="success">Success</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="failure">Failure</MenuItem>
                            </Select>
                        </FormControl>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={loading}
                            size="small"
                            // startIcon={<FileDownloadIcon />}
                            onClick={handleSearchClick}
                        >
                            Search
                        </LoadingButton>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <LoadingButton
                            variant="contained"
                            color="success"
                            loading={loadingExcel}
                            size="small"
                            startIcon={<FileDownloadIcon />}
                            onClick={handleExcelClick}
                        >
                            Export to Excel
                        </LoadingButton>
                    </Box>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={requests}
                            columns={columns}
                            pagination
                            page={page}
                            getRowId={(row) => row.id}
                            paginationMode="server"
                            rowCount={rowCount}
                            rowsPerPageOptions={[25, 50, 100]}
                            pageSize={pageSize}
                            // onPageChange={handlePageChange}
                            onPaginationModelChange={(params) => handlePageSizeChange(params)}
                            loading={loading}
                            // slots={{ toolbar: GridToolbar }}
                        />
                    </div>
                </Box>
            </LocalizationProvider>
        </>
    );
};

export default BranchRequestList;
