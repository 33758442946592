import { Box, Button, TextField, Menu, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getListDistributor, listMerchant } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { handleExcelDownloadGlobal } from 'utils/exports/excelDownload';
import PropTypes from 'prop-types';
const breadCrumbRoutes = [{ label: 'Branches', path: '' }];
const ActionMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTransactionClick = () => {
        navigate('/super-distributor/admin-branch-transactions', { state: { params: row } });
    };

    const handleProfileClick = () => {
        navigate('/super-distributor/branch-view', { state: { params: row } });
        handleClose();
    };

    return (
        <>
            <Button endIcon={<MoreVertIcon />} onClick={handleClick} size="small">
                Options
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleTransactionClick}>Transactions</MenuItem>
                <MenuItem onClick={handleProfileClick}>View Details</MenuItem>
            </Menu>
        </>
    );
};
const AllBranches = () => {
    const [listBranches, setListBranches] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            field: 'created_at',
            headerName: 'Date',
            width: 190,
            valueGetter: (params) => {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Kolkata' };
                const indianDate = new Date(params.value).toLocaleDateString('en-GB', options);
                const indianTime = new Date(params.value).toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata' });
                return `${indianDate} ${' '}${indianTime}`;
            }
        },
        {
            field: 'place',
            headerName: 'Branch Code',
            width: 110,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        {
            field: 'agname',
            headerName: 'Name',
            width: 110,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 160,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 110,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        {
            field: 'distName',
            headerName: 'District',
            width: 110,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        {
            field: 'balance',
            headerName: 'Balance',
            width: 100,
            valueGetter: (params) => {
                if (!params.value) {
                    return 'N/A';
                }
                return params.value;
            }
        },
        { field: 'actions', headerName: '', width: 110, renderCell: (params) => <ActionMenu row={params.row} /> }
    ];

    //handlers>>>
    const filteredData = listBranches?.filter(
        (item) =>
            item?.agname?.includes(search) ||
            item?.email?.includes(search) ||
            item?.phone?.includes(search) ||
            item?.distName?.includes(search) ||
            item?.balance?.includes(search)
    );
    // const handleTransactionClick = (params) => {
    //     navigate('/super-distributor/admin-branch-transactions', { state: { params } });
    // };
    //api handlers>>>
    const getBranchesList = async () => {
        setIsLoading(true);
        const distributorsListResponse = await getListDistributor();
        if (distributorsListResponse.status === 200) {
            const firstDistributor = distributorsListResponse?.data?.data[0];
            const payload = {
                paramId: firstDistributor?.id
            };
            const response = await listMerchant(payload);
            if (response?.status === 200) {
                const list = response?.data?.data;
                setListBranches(list);
            }
        }

        setIsLoading(false);
    };
    useEffect(() => {
        getBranchesList();
    }, []);

    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: '#fff',
                    borderRadius: 2,
                    boxShadow: 2,
                    marginBottom: 2,
                    padding: '20px 10px',
                    '@media screen and (min-width: 600px)': {
                        padding: 2
                    }
                }}
            >
                {/* Toolbar Section */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    {/* Search Input */}
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search by mobile number"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    {/* Export Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FileDownloadIcon />}
                        onClick={() => handleExcelDownloadGlobal(columns, filteredData, 'BRANCH_LIST')}
                    >
                        Export to Excel
                    </Button>
                </Box>

                {/* Data Grid */}
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={filteredData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        // slots={{ toolbar: GridToolbar }}
                        loading={isLoading}
                    />
                </div>
            </Box>
        </>
    );
};
export default AllBranches;

ActionMenu.propTypes = {
    row: PropTypes.object
};
