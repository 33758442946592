// assets
import { IconMoneybag } from '@tabler/icons';

// constant
const icons = {
    IconMoneybag
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const BankAccount = {
    id: 'bankAccounts',
    title: 'Fund Management',
    type: 'group',
    children: [
        {
            id: 'bankAccount1',
            title: 'Fund Management',
            icon: icons.IconMoneybag,
            type: 'item',
            url: '/bankAccount/manageBank',
            breadcrumbs: false
        }
    ]
};

export default BankAccount;
