import { useState } from 'react';
import { Typography, Grid, Box, TableContainer, Table, TableBody, TableRow, TableCell, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import BreadcrumbComponent from 'views/Components/BreadCrumb';
import { useEffect } from 'react';
import { viewBranchProfileApi } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import { useLocation, useNavigate } from 'react-router';
const breadCrumbRoutes = [
    { label: 'Branch List', path: '/super-distributor/admin-branches' },
    { label: 'Branch Details', path: '' }
];
const ViewBranchPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({});
    const [stateList, setStateList] = useState([]);
    const [districtList, setDistrictList] = useState([]);

    const id = location?.state?.params?.id;
    const date = new Date(profile?.dob);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-IN', options);
    //Handlers>>>
    const handleEditClick = () => {
        navigate('/super-distributor/branch-edit', { state: { profile, stateList, districtList } });
    };
    const modifyData = (inputData) => {
        const fullName = inputData?.agname + ' ' + inputData?.mname + ' ' + inputData?.lname;
        const newData = { ...inputData, fullName };
        return newData;
    };
    //api handlers>>>
    const getProfileDetails = async () => {
        const payload = {
            id
        };
        const response = await viewBranchProfileApi(payload);
        if (response?.status === 200) {
            const data = response?.data?.data;
            const modifiedData = modifyData(data);
            const districts = response?.data?.dst;
            const states = response?.data?.states;
            setStateList(states);
            setDistrictList(districts);
            setProfile(modifiedData);
        }
    };
    useEffect(() => {
        getProfileDetails();
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <BreadcrumbComponent routes={breadCrumbRoutes} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            padding: '20px 10px',
                            position: 'relative' // Add relative positioning
                        }}
                    >
                        {/* Edit Button */}
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10 // Position to the top-right
                            }}
                            onClick={handleEditClick} // Function to handle edit action
                        >
                            <EditIcon />
                        </IconButton>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 150 }} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Name
                                        </TableCell>
                                        <TableCell>{[profile?.agname, profile?.mname, profile?.lname].filter(Boolean).join(' ')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Email
                                        </TableCell>
                                        <TableCell>{profile?.email}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Phone
                                        </TableCell>
                                        <TableCell>{profile?.phone}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Gender
                                        </TableCell>
                                        <TableCell>{profile?.gender}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            State
                                        </TableCell>
                                        <TableCell>{profile?.state_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            District
                                        </TableCell>
                                        <TableCell>{profile?.district_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Address
                                        </TableCell>
                                        <TableCell>{profile?.address}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Date of Birth
                                        </TableCell>
                                        <TableCell>{formattedDate}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Pincode
                                        </TableCell>
                                        <TableCell>{profile?.pin}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Landmark
                                        </TableCell>
                                        <TableCell>{profile?.place}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Balance
                                        </TableCell>
                                        <TableCell style={{ color: 'red' }}>{profile?.balance}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            // ...commonStyles,
                            borderRadius: 2,
                            padding: '20px 10px',
                            '@media screen and (min-width: 600px)': {
                                // padding: 1
                            }
                        }}
                    >
                        <Typography variant="h3" component="h2" sx={{ ml: 1, mb: 1 }}>
                            {/* Login Details */}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                <TableBody>
                                    {/* {profile.map((row, index) => ( */}
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            AccountName
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.accountname}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            AccontNumber
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.accountno}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            BankName
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.bank_name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Branch
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.bank_branch}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            IFSC
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.bank_ifsc}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Kyc Type
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profile?.kyctype}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default ViewBranchPage;
