import PropTypes from 'prop-types';
import { useState } from 'react';
// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Card, Grid, InputAdornment, OutlinedInput, Popper } from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';
import { useEffect } from 'react';

// styles
import './index.css';
import { useSelector } from 'react-redux';
import SearchDialog from 'views/Components/Search/SearchDialog';
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: '100%', // Ensure it adapts within its container
    maxWidth: 250, // Set a reasonable max-width
    marginLeft: 10, // Reduce margin
    paddingLeft: 12,
    paddingRight: 12,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        maxWidth: 200 // Further reduce width on smaller screens
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '80%', // Adjust for responsiveness
        marginLeft: 4,
        background: '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    '&:hover': {
        background: theme.palette.primary.dark,
        color: theme.palette.primary.light
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            id="input-search-header"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            autoComplete="new_password"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <ButtonBase sx={{ borderRadius: '12px' }}>
                        <HeaderAvatarStyle variant="rounded">
                            <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                        </HeaderAvatarStyle>
                    </ButtonBase>
                    <Box sx={{ display: { xs: 'none', md: 'block' }, flexShrink: 0 }}>
                        <ButtonBase sx={{ borderRadius: '12px' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    background: theme.palette.orange.light,
                                    color: theme.palette.orange.dark,
                                    '&:hover': {
                                        background: theme.palette.orange.dark,
                                        color: theme.palette.orange.light
                                    }
                                }}
                                {...bindToggle(popupState)}
                            >
                                <IconX stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //
// eslint-disable-next-line
const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    const mode = useSelector((state) => state.theme.mode);
    const [openDialog, setOpenDialog] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const role = localStorage.getItem('role');
    useEffect(() => {
        setValue('');
    }, []);
    useEffect(() => {
        if (role === 'merchants') {
            setShowSearch(true);
        }
    }, [role]);
    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    useEffect(() => {
        if (openDialog) {
            const timer = setTimeout(() => {
                setOpenDialog(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [openDialog]);

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                {showSearch && <SearchDialog openDialog={openDialog} />}
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleClickOpen}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconSearch stroke={1.5} size="1.2rem" />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={value} setValue={setValue} popupState={popupState} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <OutlineInputStyle
                    id="input-search-header"
                    className="searchbox-input"
                    value={value}
                    autoComplete="nothing_required"
                    onClick={handleClickOpen}
                    placeholder="Search"
                    sx={{ backgroundColor: mode === 'dark' ? '#797979' : '', color: '#fff' }}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                        </InputAdornment>
                    }
                    // endAdornment={
                    //     <InputAdornment position="end">
                    //         <ButtonBase sx={{ borderRadius: '12px' }}>
                    //             <HeaderAvatarStyle variant="rounded">
                    //                 <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                    //             </HeaderAvatarStyle>
                    //         </ButtonBase>
                    //     </InputAdornment>
                    // }
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                />
            </Box>
        </>
    );
};

export default SearchSection;
