import {
    Typography,
    Grid,
    Paper,
    Box,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import { useEffect } from 'react';
import { getProfileApi, resetPassword } from 'utils/API/Superdistributor APIs/SuperdistributorApiCalls';
import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { globalNotifyError, globalNotifySuccess } from 'views/utilities/AlertToast';
import { LoadingButton } from '@mui/lab';

const AdminProfile = () => {
    const [profileDetails, setProfileDetails] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [old_password, setOldPassword] = useState('');
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    //api calls>>>
    const getProfileDetails = async () => {
        const response = await getProfileApi();
        if (response?.status === 200) {
            const profileDetails = response?.data?.data;
            const dist = response?.data?.dst;
            const kycImage = response?.data?.kyc_img;
            setProfileDetails({ ...profileDetails, districtName: dist?.dist, kycImage });
        }
    };
    const handleResetPassword = async () => {
        if (password !== password_confirmation) {
            globalNotifyError('Password and confirm password should be same.');
            return;
        }
        setIsLoading(true);
        const payload = {
            password,
            password_confirmation,
            old_password
        };

        const response = await resetPassword(payload);
        setIsLoading(false);
        if (response?.status === 200) {
            globalNotifySuccess(response?.data?.message);
            setPassword('');
            setPassword_confirmation('');
            setOldPassword('');
        }
    };

    //handlers>>>
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);

        // Password validation: at least one letter, one digit, and one special character
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

        setIsValidPassword(passwordRegex.test(newPassword));
    };
    useEffect(() => {
        getProfileDetails();
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            padding: '20px 10px',
                            '@media screen and (min-width: 600px)': {
                                // padding: 1
                            }
                        }}
                    >
                        <Typography variant="h3" color="info.light" component="h2" sx={{ ml: 1, mb: 1 }}>
                            {`Admin Profile`}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Name
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.fname} {profileDetails?.lname}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Email
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.email}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Phone
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.phone}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            District
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.districtName}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Address
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.address}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Date of Birth
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.dob}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Pincode
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.pincode}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            Landmark
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {profileDetails?.landmark}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            KYC
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <img
                                                style={{ width: '200px', height: '150px', transition: 'transform 0.3s ease-in-out' }}
                                                src={profileDetails?.kycImage}
                                                alt="KYC"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {/* ))} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#fff',
                            // ...commonStyles,
                            borderRadius: 2,
                            padding: '20px 10px',
                            '@media screen and (min-width: 600px)': {
                                // padding: 1
                            }
                        }}
                    >
                        <Typography variant="h3" component="h2" sx={{ ml: 1, mb: 1 }}>
                            Reset Password
                        </Typography>
                        <FormControl sx={{ width: '34ch', mb: 1 }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password1">Old Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password1"
                                type={showPassword ? 'text' : 'password'}
                                value={old_password}
                                autoComplete="set_password"
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <FormControl sx={{ width: '34ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password2">New Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password2"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                autoComplete="set_password"
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                            {!isValidPassword && (
                                <p style={{ color: 'red' }}>
                                    Password must contain at least 10 characters, one uppercase letter, one lowercase letter, one digit, and
                                    one special character.
                                </p>
                            )}
                        </FormControl>
                        <FormControl sx={{ mt: 1, width: '34ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password3">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password3"
                                type={showPassword ? 'text' : 'password'}
                                value={password_confirmation}
                                onChange={(e) => {
                                    setPassword_confirmation(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <LoadingButton loading={isLoading} variant="contained" sx={{ ml: 1, mt: 1 }} onClick={handleResetPassword}>
                            Reset Password
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminProfile;
