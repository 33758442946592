import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export const handleExcelDownloadGlobal = (columns, dataInput, fileName) => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet('Data');

    // Add the column headers to the worksheet
    const headerRow = worksheet.addRow(columns.map((column) => column?.headerName));
    headerRow.font = { bold: true };

    // Add the data rows to the worksheet
    dataInput.forEach((rowData) => {
        const dataRow = worksheet.addRow(columns.map((column) => rowData[column?.field]));
        dataRow.alignment = { vertical: 'middle', horizontal: 'left' };
    });

    // Download the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${fileName}.xlsx`);
    });
};
