import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import ProtectedRouteCsp from 'routes/protectedRouteCsp';

const DashboardCsp = Loadable(lazy(() => import('views/cspDashboard/dashboard')));
const RelationManagerProfile = Loadable(lazy(() => import('views/cspDashboard/profile')));
const SuperDistributorForm = Loadable(lazy(() => import('views/cspDashboard/superdistributor/add')));
const SuperDistributorList = Loadable(lazy(() => import('views/cspDashboard/superdistributor/list')));
const DistributorList = Loadable(lazy(() => import('views/cspDashboard/distributor/list')));
const DistributorForm = Loadable(lazy(() => import('views/cspDashboard/distributor/add')));
const ViewSuperdistributor = Loadable(lazy(() => import('views/cspDashboard/viewsuperdistributor')));
const ViewDistributor = Loadable(lazy(() => import('views/cspDashboard/viewdistributor')));
const DailySalesMonitoring = Loadable(lazy(() => import('views/cspDashboard/dailySalesMonitoring')));
const POSTxnForm = Loadable(lazy(() => import('views/cspDashboard/merchant-txn-track/pos')));
const QRTxnForm = Loadable(lazy(() => import('views/cspDashboard/merchant-txn-track/qr')));
const AEPSTxnForm = Loadable(lazy(() => import('views/cspDashboard/bc-txn-track/aeps')));
const BBPSTxnForm = Loadable(lazy(() => import('views/cspDashboard/bc-txn-track/bbps')));
const DMTTxnForm = Loadable(lazy(() => import('views/cspDashboard/bc-txn-track/dmt')));
const MATMTxnForm = Loadable(lazy(() => import('views/cspDashboard/bc-txn-track/matm')));
const RechargeTxnForm = Loadable(lazy(() => import('views/cspDashboard/bc-txn-track/recharge')));
const ListMerchant = Loadable(lazy(() => import('views/cspDashboard/merchant/list')));
const ViewMerchant = Loadable(lazy(() => import('views/cspDashboard/viewMerchant')));

export const cspRoute = {
    path: '',
    children: [
        {
            path: 'dashboard',
            element: <ProtectedRouteCsp element={<DashboardCsp />} />
        },
        {
            path: 'profile',
            element: <ProtectedRouteCsp element={<RelationManagerProfile />} />
        },
        {
            path: 'addsuperdistributor',
            element: <ProtectedRouteCsp element={<SuperDistributorForm />} />
        },
        {
            path: 'listsuperdistributor',
            element: <ProtectedRouteCsp element={<SuperDistributorList />} />
        },
        {
            path: 'listdistributor',
            element: <ProtectedRouteCsp element={<DistributorList />} />
        },
        {
            path: 'adddistributor',
            element: <ProtectedRouteCsp element={<DistributorForm />} />
        },
        {
            path: 'ViewSuperdistributor',
            element: <ProtectedRouteCsp element={<ViewSuperdistributor />} />
        },

        {
            path: 'ViewDistributor',
            element: <ProtectedRouteCsp element={<ViewDistributor />} />
        },
        {
            path: 'dailySalesMonitoring',
            element: <ProtectedRouteCsp element={<DailySalesMonitoring />} />
        },
        {
            path: 'posTxnTrack',
            element: <ProtectedRouteCsp element={<POSTxnForm />} />
        },
        {
            path: 'qrTxnTrack',
            element: <ProtectedRouteCsp element={<QRTxnForm />} />
        },
        {
            path: 'aepsTxnTrack',
            element: <ProtectedRouteCsp element={<AEPSTxnForm />} />
        },
        {
            path: 'bbpsTxnTrack',
            element: <ProtectedRouteCsp element={<BBPSTxnForm />} />
        },
        {
            path: 'dmtTxnTrack',
            element: <ProtectedRouteCsp element={<DMTTxnForm />} />
        },
        {
            path: 'matmTxnTrack',
            element: <ProtectedRouteCsp element={<MATMTxnForm />} />
        },
        {
            path: 'rechargeTxnTrack',
            element: <ProtectedRouteCsp element={<RechargeTxnForm />} />
        },
        {
            path: 'listmerchant',
            element: <ProtectedRouteCsp element={<ListMerchant />} />
        },
        {
            path: 'ViewMerchant',
            element: <ProtectedRouteCsp element={<ViewMerchant />} />
        }
    ]
};
