/* eslint-disable */

// assets
import { IconUserCircle } from '@tabler/icons';

// constant
const icons = {
    IconUserCircle
};
// Function to get title based on condition
const getTitle = (defaultTitle, bcTitle) => {
    const isBCWalletTrue = localStorage.getItem('bcWalletSuperDist'); // Check localStorage at runtime
    return isBCWalletTrue === 'true' ? defaultTitle : bcTitle;
};
const getTitleLabel2 = () => {
    const label2 = localStorage.getItem('label2');
    return label2;
};
const getTitleLabel3 = () => {
    const label3 = localStorage.getItem('label3');
    return label3;
};
// ==============================|| UTILITIES MENU ITEMS ||============================== //

const Dashboard = {
    id: 'newUtilities',
    // title: 'Super distributor',
    type: 'group',
    children: [
        // {
        //     id: 'icons1',
        //     title: 'Super Distributor',
        //     type: 'collapse',
        //     icon: icons.IconUserCircle,
        //     children: [
        {
            id: 'tabler-icons112006340033',
            title: 'Dashboard',
            type: 'item',
            url: 'managerDashboard/dashboard',
            breadcrumbs: true
        },
        {
            id: 'icons22314344434222',
            title: 'Super Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3124413032107009',
                    title: 'Add',
                    type: 'item',
                    url: 'managerDashboard/addsuperdistributor',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5323030220675',
                    title: 'List',
                    type: 'item',
                    url: '/managerDashboard/listsuperdistributor',
                    breadcrumbs: false
                }
               
            ]
        },
        {
            id: 'icons22313443227',
            title: 'Distributor',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons532241122839001',
                    title: 'Add',
                    type: 'item',
                    url: '/managerDashboard/adddistributor',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons532221138144129',
                    title: 'List',
                    type: 'item',
                    url: '/managerDashboard/listdistributor',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231202355332',
            title: 'Csp/Sanchalak',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312104012220',
                    title: 'Add',
                    type: 'item',
                    url: 'managerDashboard/addcsp',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322011254222',
                    title: 'List',
                    type: 'item',
                    url: '/managerDashboard/listcsp',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons2231232564',
            title: 'Merchant',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons5322110309',
                    title: 'List',
                    type: 'item',
                    url: '/managerDashboard/listmerchant',
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'tabler-icons03013355',
            title: 'Daily Sales Monitoring',
            type: 'item',
            url: 'managerDashboard/dailySalesMonitoring',
            breadcrumbs: true
        },
        {
            id: 'icons2231223322',
            title: 'Merchant Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons312103112',
                    title: 'POS',
                    type: 'item',
                    url: 'managerDashboard/posTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons53322111',
                    title: 'QR',
                    type: 'item',
                    url: '/managerDashboard/qrTxnTrack',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'icons22312235367',
            title: 'BC Txn Track',
            type: 'collapse',
            children: [
                {
                    id: 'tabler-icons3121301009',
                    title: 'DMT',
                    type: 'item',
                    url: 'managerDashboard/dmtTxnTrack',
                    breadcrumbs: true
                },
                {
                    id: 'tabler-icons5322113008',
                    title: 'BBPS',
                    type: 'item',
                    url: '/managerDashboard/bbpsTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322131001',
                    title: 'RECHARGE',
                    type: 'item',
                    url: '/managerDashboard/rechargeTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5322311002',
                    title: 'MATM',
                    type: 'item',
                    url: '/managerDashboard/matmTxnTrack',
                    breadcrumbs: false
                },
                {
                    id: 'tabler-icons5332211003',
                    title: 'AEPS',
                    type: 'item',
                    url: '/managerDashboard/aepsTxnTrack',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default Dashboard;
